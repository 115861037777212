import { Container, Link } from "@mui/material";

import React, { useEffect, useState } from "react";
import { Card, CardContent, Grid, Typography, Box } from "@mui/material";

import { getSingleProduct } from "../../../store/slices/product";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { BASE_URL } from "../../../api/config";
import { useTheme } from "@mui/material/styles";
import NotFoundPage from "../NotFoundPage";

const RelatedProductsSetion = (relatedProducts: any) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const theme = useTheme();

	const itemHandler = async (link: string) => {
		const urlParams = new URLSearchParams(link.split("?")[1]);
		const productId = urlParams.get("product_id");
		navigate(link);
		if (productId) {
			dispatch<any>(getSingleProduct(Number(productId)));
		}
	};

	return (
		<>
			{relatedProducts.relatedProducts && (
				<Container
					sx={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						background: theme.palette.background.default,
						borderRadius: 2,
						minHeight: 400,
						mt: 5,
					}}
				>
					<CardContent>
						<Typography variant="h2" textAlign="center" my={5} sx={{ fontSize: { xs: "1.5rem", sm: "2.125rem" } }}>
							Bạn quan tâm đến sản phẩm nào của chúng tôi?
						</Typography>
						<Grid container spacing={2}>
							{typeof relatedProducts?.relatedProducts?.map === "function" &&
								relatedProducts?.relatedProducts?.map((product: any, index: number) => {
									const icon = `${BASE_URL}${product?.attributes?.image?.data[0].attributes.url}`;
									return (
										<Grid
											item
											xs={6}
											sm={6}
											md={3}
											key={index}
											onClick={() => itemHandler(product?.attributes?.link)}
											sx={{
												display: "flex",
												flexDirection: "column",
												alignItems: "center",
											}}
										>
											<LazyLoadImage src={icon} alt={product?.attributes?.name} style={{ width: "100px", height: "auto" }} />
											<Typography variant="subtitle1" align="center" sx={{ marginTop: 2 }}>
												{product?.attributes?.name}
											</Typography>
										</Grid>
									);
								})}
						</Grid>
					</CardContent>
				</Container>
			)}
		</>
	);
};

export default RelatedProductsSetion;
