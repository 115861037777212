// material-ui
import { styled } from '@mui/material/styles';

// project imports
import ContactSale from './ContactSale';
import { Box } from '@mui/material';



// ============================|| CONTACT US MAIN ||============================ //

const ContactUsPage = () => (
    <Box sx={{ borderRadius: 2, minHeight: { xs: "920px", sm: "800px", md: "800px", lg: "800px", border: "0.1px solid rgba(128,128,128,0.2)" }, boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.3)" }} >
        <ContactSale />
    </Box >
);

export default ContactUsPage;
