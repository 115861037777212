import { useMemo } from "react";
import MenuData from "./dashboard";

export const DataMenuItems = () => {
  try {
    const menuData = MenuData();

    const convertedData = useMemo(() => {
      try {
        return menuData;
      } catch (error) {
        console.error("Error fetching and converting data:", error);
        throw error;
      }
    }, [menuData]);

    return { items: convertedData };
  } catch (error) {
    console.error("Error fetching and converting data:", error);
    throw error;
  }
};
