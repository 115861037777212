import { createTheme } from "@mui/material/styles";

// assets
import colors from "../assets/scss/_theme-vars.module.scss";

// project imports
import componentStyleOverrides from "./compStyleOverride";
import themePalette from "./palette";
import { useMemo } from "react";
import useConfig from "../hooks/useConfig";
import themeTypography from "./typography";
/**
 * Represent theme style and structure as per Material-UI
 * @param {JsonObject} customization customization parameter object
 */

export const theme = (customization: any) => {
  const color = colors;


  const themeOption = {
    colors: colors,
    heading: color.darkTextTitle,
    paper: color.darkLevel1,
    backgroundDefault: color.darkLevel1,
    background: color.darkLevel2,
    darkTextPrimary: color.darkTextPrimary,
    darkTextSecondary: color.darkTextSecondary,
    textDark: color.darkTextPrimary,
    menuSelected: color.darkSecondaryMain,
    menuSelectedBack: "rgba(124, 77, 255, 0.082)",
    divider: "rgb(189, 200, 240, 0.2)",
    tr: "rgb(189, 200, 240, 0.2)",
    customization,
  };


  const themeOptions: any = {
    direction: "ltr",
    palette: themePalette(themeOption),
    mixins: {
      toolbar: {
        minHeight: "48px",
        padding: "16px",
        "@media (min-width: 600px)": {
          minHeight: "48px",
        },
      },
    },
    typography: themeTypography(themeOption),
  };

  const themes = createTheme(themeOptions);
  themes.components = componentStyleOverrides(themeOption);

  return themes;
};

export default theme;
