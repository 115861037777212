import React from "react";
import {
  Card,
  Typography,
  Stack,
  Divider,
  Button,
  Box,
  FormControl,
  InputLabel,
  OutlinedInput,
  Grid,
  FormHelperText,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { gridSpacing } from "../../../store/constant";

export default function MobileCard(pack: any, productName: string) {
  const theme = useTheme();
  const navigate = useNavigate();

  const [value, setValue] = React.useState<Number>();

  const handleChange = (event: any) => {
    setValue(event.target.value as Number);
  };

  const handleOrderNowClick = (prodPack: any) => {
    const currentUrl = window.location.href;
    const pathname = new URL(currentUrl).pathname;
    const decodedPathname = decodeURIComponent(pathname);
    if (prodPack?.attributes?.packageCode.startsWith("OSS")) {
      window.open(
        `${process.env.REACT_APP_PORTAL_URL}/storage/object/buy-package?code=${prodPack?.attributes?.packageCode}`,
        "_blank"
      );
    } else {
      navigate("/contact", {
        state: {
          packageDetail: prodPack?.attributes || `${prodPack} TB`,
          productName: prodPack?.attributes?.productName || decodedPathname,
        },
      });
    }
  };

  return (
    <Box>
      <Grid container spacing={1}>
        {pack?.pack?.attributes?.package?.data?.map((pack: any, i: number) => {
          return (
            <Grid item xs={12} sm={12} md={4} lg={4} key={i}>
              <Card
                sx={{
                  p: 2,
                  background: `${theme.palette.background.default}`,
                  border: "1px solid",
                  borderColor: "transparent",
                  "&:hover": {
                    border: `1px solid${theme.palette.primary.main}`,
                  },
                  m: 3,
                  minWidth: "200px",
                }}
              >
                {pack?.attributes?.attributes?.map((item: any) => {
                  return (
                    <Box key={item?.id}>
                      <Stack
                        flexDirection="row"
                        flexWrap="wrap"
                        justifyContent="space-between"
                        alignItems="start"
                      >
                        <Typography variant="h5"> {item?.title}</Typography>
                        <Typography>
                          {" "}
                          {item?.value.toLocaleString()} {item?.unit}{" "}
                        </Typography>
                      </Stack>
                    </Box>
                  );
                })}
                <Divider sx={{ mt: 1 }} />
                <Stack sx={{ mt: 1 }}>
                  <Stack
                    flexDirection="row"
                    flexWrap="wrap"
                    justifyContent="space-between"
                    alignItems="start"
                  >
                    <Typography variant="h5">Giá/Tháng</Typography>
                    <Typography variant="h4">
                      {pack?.attributes?.price?.toLocaleString()} VND
                    </Typography>
                    {/* <Typography variant="h4">
                    {pack?.attributes?.unitPrice?.toLocaleString()} VND
                  </Typography> */}
                  </Stack>
                  <Button
                    variant="contained"
                    sx={{ mt: 1 }}
                    onClick={() => handleOrderNowClick(pack)}
                  >
                    {pack.attributes.packageCode.startsWith("OSS")
                      ? "Mua Ngay"
                      : "Liên Hệ"}
                  </Button>
                </Stack>
              </Card>
            </Grid>
          );
        })}
      </Grid>
      {pack?.pack?.attributes?.package?.data[0]?.attributes?.packageCode?.startsWith(
        "OSS"
      ) && (
          <Grid container spacing={gridSpacing}>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <Card
                sx={{
                  p: 2,
                  background: `${theme.palette.background.default}`,
                  border: "1px solid",
                  borderColor: "transparent",
                  "&:hover": {
                    border: `1px solid${theme.palette.primary.main}`,
                  },
                  m: 3,
                }}
              >
                <Stack flexDirection="column" justifyContent="space-between">
                  <Typography variant="caption">
                    Bạn cần dung lượng lớn hơn?
                  </Typography>
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <FormControl>
                      <InputLabel>Nhập dung lượng</InputLabel>
                      <OutlinedInput
                        type="number"
                        label="Nhập dung lượng"
                        name="objectValue"
                        placeholder="Nhập dung lượng"
                        value={value}
                        onChange={handleChange}
                      />
                      {Number(value) < 10 && (
                        <FormHelperText style={{ color: "red" }}>
                          Vui lòng nhập lớn hơn 10 TB
                        </FormHelperText>
                      )}
                    </FormControl>
                    <Typography variant="h4">TB</Typography>
                  </Stack>
                  <Button
                    variant="contained"
                    onClick={() => handleOrderNowClick(Number(value) ?? 0)}
                    disabled={Number(value) < 10}
                    style={{
                      marginTop: "20px",
                      backgroundColor: Number(value) > 10 ? "#f3ba0d" : "grey",
                      color: Number(value) > 10 ? "#fff" : "black",
                    }}
                  >
                    Liên Hệ
                  </Button>
                </Stack>
              </Card>
            </Grid>
          </Grid>
        )}
    </Box>
  );
}
