import React, { Fragment, useRef } from "react";
import { Button, Card, Grid, Typography, useMediaQuery, Box, Stack, Container } from "@mui/material";
import UseCaseCard from "../../ui-component/card/UseCaseCard";
import HighlightCard from "../../ui-component/card/HighlightCard";
import CompareChartData from "../../ui-component/chart/CompareChartCard";
import { Helmet } from "react-helmet";
// project import
import { BASE_URL } from "../../api/config";
import MainCard from "../../ui-component/card/MainCard";
import ProductPrice from "../pricing/tabProduct/ProductPrice";
import MobileCard from "../pricing/pricecard/MobileCard";
import CustomizeSection from "./Blog/CustomizeSection";
import FeatureSection from "./Blog/FeatureSection";
import BreadCrumbs from "../../ui-component/BreadCrumbs";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { useTheme, styled } from "@mui/material/styles";
import { gridSpacing } from "../../store/constant";
import ImageAnimationSection from "../../ui-component/ImageAnimationSection";
import SystemMetricsSection from "../../ui-component/SystemMetricsSection";

interface ProductDetailProps {
  productDetail: any;
  categoryId: any;
  categoryName: string;
}

const HeaderAnimationImage = styled("img")({
  maxWidth: "100%",
  filter: "drop-shadow(0px 0px 50px rgb(33 150 243 / 30%))",
});


const ProductDetailComponent: React.FC<ProductDetailProps> = ({
  productDetail,
  categoryId,
  categoryName,
}) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery("(max-width:960px)");
  const targetSectionRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();


  const scrollToSection = () => {
    targetSectionRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const headerSX = {
    fontSize: { xs: "2rem", sm: "3rem", md: "3.5rem", lg: "3.5rem" },
    color: "rgb(33, 150, 243)",
  };


  const headerImg =
    productDetail?.attributes?.blog_image_header?.data?.attributes?.url;

  return (
    <>
      <Helmet>
        <title>{productDetail?.attributes?.name}</title>
        <meta
          name="description"
          content={productDetail?.attributes?.description}
        />
      </Helmet>
      <Container
        sx={{
          minHeight: "100vh",
          marginTop: "50px",
          padding: { xs: 0, md: 4 },
          background: theme.palette.background.paper,
          borderRadius: 2,
        }}
      >
        <BreadCrumbs
          categoryName={categoryName}
          productName={productDetail?.attributes?.name}
        />
        <Box sx={{ mb: 10, mt: 1 }}>
          {productDetail?.attributes?.blog_image_header && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                background: theme.palette.background.default,
                borderRadius: 2,
                minHeight: 400,
              }}
            >
              <Grid
                container
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                sx={{ m: { xs: 1, md: 3 }, padding: 0 }}
                spacing={5}
              >
                <Grid item xs={12} md={5} lg={5} style={{ padding: 2 }}>
                  <Grid container spacing={gridSpacing}>
                    <Grid item xs={12} sx={{ mb: 2, mt: 2 }}>
                      <motion.div
                        initial={{ opacity: 0, translateY: 550 }}
                        animate={{ opacity: 1, translateY: 0 }}
                        transition={{ type: "spring", stiffness: 150, damping: 30 }}
                      >
                        <Stack spacing={1}>
                          <Typography
                            textAlign="start"
                            variant="h1"
                            sx={headerSX}
                          >
                            {productDetail?.attributes?.name}
                          </Typography>
                        </Stack>
                      </motion.div>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sx={{
                        mt: -2.5,
                        // textAlign: { xs: "center", sm: "center", md: "left" },
                      }}
                    >
                      <motion.div
                        initial={{ opacity: 0, translateY: 550 }}
                        animate={{ opacity: 1, translateY: 0 }}
                        transition={{
                          type: "spring",
                          stiffness: 150,
                          damping: 30,
                          delay: 0.2,
                        }}
                      >
                        <Typography
                          textAlign="justify"
                          color="text.primary"
                          variant="subtitle1"
                          sx={{ fontSize: { xs: "1rem", md: "1.125rem" } }}
                        >
                          {productDetail?.attributes?.description}
                        </Typography>
                      </motion.div>
                    </Grid>
                    <Grid item xs={12}>
                      <motion.div
                        initial={{ opacity: 0, translateY: 550 }}
                        animate={{ opacity: 1, translateY: 0 }}
                        transition={{
                          type: "spring",
                          stiffness: 150,
                          damping: 30,
                          delay: 0.4,
                        }}
                      >
                        <Grid
                          container
                          spacing={1}
                          sx={{ justifyContent: { xs: "center", md: "flex-start" } }}
                        >
                          <Grid item sx={{ mt: 3 }}>
                            <Button
                              onClick={scrollToSection}
                              size="medium"
                              variant="contained"
                            >
                              Kích hoạt ngay
                            </Button>
                          </Grid>
                          <Grid item sx={{ mt: 3 }}>
                            <Button
                              onClick={() => navigate("/pricing")}
                              size="medium"
                              variant="outlined"
                            >
                              Bảng Giá
                            </Button>
                          </Grid>
                          <Grid item sx={{ mt: 3 }}>
                            <Button
                              onClick={() => window.open('https://docs.x-or.cloud/', "_blank")}
                              size="medium"
                              variant="outlined"
                            >
                              Tài liệu dịch vụ
                            </Button>
                          </Grid>
                        </Grid>
                      </motion.div>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={7}
                  lg={7}
                  style={{ padding: 2, display: "block" }}
                >
                  <Box sx={{ position: "relative", mt: 8.75, zIndex: 9 }}></Box>
                  <HeaderAnimationImage
                    loading="lazy"
                    src={`${BASE_URL}${headerImg}`}
                    alt="x-or cloud header image"
                    sx={{
                      display: { xs: "block", md: "flex" },
                      alignItems: "center",
                      position: "relative",
                      filter: "none",
                      bottom: { md: 0 },
                      marginLeft: { xs: 0, md: "20px" },
                      width: "100%",
                      transformOrigin: "50% 50%",
                      padding: 1
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
          )}
        </Box>
        {productDetail?.attributes?.section_img_animations?.data?.length > 0 && (
          productDetail?.attributes?.section_img_animations?.data?.map((img: any, index: any) => {
            return (
              <ImageAnimationSection key={index} images={img?.attributes?.images} />
            )

          })
        )}
        {/* {productDetail?.attributes?.section_img_animations?.data[0]?.attributes?.images.length > 0 && (
          <ImageAnimationSection images={productDetail?.attributes?.section_img_animations?.data[0]?.attributes?.images} />
        )} */}
        {productDetail?.attributes?.section_system_metrics?.data?.length > 0 && (
          productDetail?.attributes?.section_system_metrics?.data?.map((section: any, index: number) => {
            return (
              <Box key={index} sx={{ padding: 1, mt: 10 }}>
                <SystemMetricsSection section={section} />
              </Box>
            )
          })
        )}
        <Card>
          <CompareChartData
            product={productDetail}
            categoryId={categoryId}
            column="5"
            style={{ flex: 1 }}
          />
        </Card>
        {productDetail?.attributes?.usecases?.data?.length > 0 && (
          <Box sx={{ m: 1 }} >
            <Typography variant="h2" textAlign="center" mb={5} mt={5} sx={{ fontSize: { xs: '1.5rem', sm: '2.125rem' } }}>Các tình huống và giải pháp</Typography>
            <Grid
              container
              spacing={2}
              sx={{ mb: 5 }}
            >
              {productDetail?.attributes?.usecases?.data?.map(
                (usecase: any, index: number) => {
                  const icon = `${BASE_URL}${usecase?.attributes?.icon?.data?.attributes?.url}`;
                  return (
                    <Fragment key={index}>
                      <Grid item xs={12} sm={6} md={4} lg={4} sx={{ mt: 3 }}>
                        <UseCaseCard
                          title={usecase?.attributes?.title}
                          icon={icon}
                          des={usecase?.attributes?.des_use_case}
                          url={usecase?.attributes?.use_case_link}
                        />
                      </Grid>
                    </Fragment>
                  );
                }
              )}
            </Grid>
          </Box>
        )}
        {productDetail?.attributes?.highlight?.length > 0 && (
          <>
            <Typography variant="h2" textAlign="center" mb={5} mt={5} sx={{ fontSize: { xs: '1.5rem', sm: '2.125rem' } }}>
              {productDetail?.attributes?.title_highlight}
            </Typography>
            <MainCard>
              <Grid
                container
                spacing={2}
                sx={{ mb: 5 }}
              >
                {productDetail?.attributes?.highlight?.map(
                  (highlight: any, index: number) => {
                    const icon = `${BASE_URL}${highlight?.icon?.data?.attributes?.url}`;
                    return (
                      <Fragment key={index}>
                        <Grid item xs={12} sm={6} md={4} lg={4} sx={{ mt: 3 }}>
                          <HighlightCard
                            title={highlight?.title}
                            icon={icon}
                            description={highlight?.description}
                          />
                        </Grid>
                      </Fragment>
                    );
                  }
                )}
              </Grid>
            </MainCard>
          </>
        )}
        <Box sx={{ mt: 10 }}>
          <CustomizeSection productDetail={productDetail} />
        </Box>
        <FeatureSection productDetail={productDetail} />
        {productDetail?.attributes?.package?.data?.length > 0 && (
          <div ref={targetSectionRef} id="targetSection">
            <Box sx={{ mt: 5, mb: 5, padding: 2 }}>
              <Typography variant="h2" mb={3} textAlign="center" sx={{ fontSize: { xs: '1.5rem', sm: '2.125rem' } }}>
                {productDetail?.attributes?.title_package}
              </Typography>
              <Typography variant="body1" textAlign="justify">
                {productDetail?.attributes?.description}
              </Typography>
            </Box>
            {isSmallScreen ? (
              <>
                <MobileCard pack={productDetail} />
              </>
            ) : (
              <>
                <ProductPrice product={productDetail} />
              </>
            )}
          </div>
        )}
      </Container >
    </>
  );
};

export default ProductDetailComponent;
