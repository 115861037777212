import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Card,
  Container,
  Grid,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { useNavigate } from "react-router-dom";
import { IRootState } from "../../../store/reducer";
import { Dispatch } from "redux";
import { getAllHomePage } from "../../../store/Slice/Home";
import { useTheme } from "@mui/material/styles";
import { BASE_URL } from "../../../api/config";
import { getSingleProduct } from "../../../store/slices/product";
import { getSingleCategories } from "../../../store/slices/category";
import { gridSpacing } from "../../../store/constant";
import SubCard from "../../../ui-component/card/SubCard";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import FadeInWhenVisible from "../../../ui-component/Animation";
import ImageAnimationSection from "../../../ui-component/ImageAnimationSection";
import SystemMetricsSection from "../../../ui-component/SystemMetricsSection";

// function SampleNextArrow(props: any) {
//   const theme = useTheme();
//   const { onClickHandler } = props;

//   return (
//     <IconButton
//       onClick={onClickHandler}
//       sx={{
//         position: "absolute",
//         zIndex: 2,
//         top: "calc(70% - 70px)",
//         cursor: "pointer",
//         background: `rgba(255,255,255,0.1) !important`,
//         width: { xs: "40px !important", xl: "65px !important" },
//         height: { xs: "40px !important", xl: "65px !important" },
//         boxShadow: "0px 24px 38px rgba(9, 15, 37, 0.07)",
//         "&:after": {
//           content: '""',
//           position: "absolute",
//           top: 0,
//           left: 0,
//           bottom: 0,
//           right: 0,
//           transform: "scale(2)",
//         },
//         svg: {
//           height: { md: 20, lg: 40, xl: "40px" },
//           width: { md: 20, lg: 40, xl: "40px" },
//         },
//         right: 0,
//       }}
//       aria-label="button"
//     >
//       <IconChevronRight fontSize={25} color={theme.palette.grey[900]} />
//     </IconButton>
//   );
// }

// function SamplePrevArrow(props: any) {
//   const { onClickHandler } = props;
//   const theme = useTheme();
//   return (
//     <IconButton
//       onClick={onClickHandler}
//       sx={{
//         position: "absolute",
//         zIndex: 2,
//         top: "calc(70% - 70px)",
//         cursor: "pointer",
//         background: `rgba(255,255,255,0.1) !important`,
//         width: { xs: "40px !important", xl: "65px !important" },
//         height: { xs: "40px !important", xl: "65px !important" },
//         boxShadow: "0px 24px 38px rgba(9, 15, 37, 0.07)",
//         "&:after": {
//           content: '""',
//           position: "absolute",
//           top: 0,
//           left: 0,
//           bottom: 0,
//           right: 0,
//           transform: "scale(2)",
//         },
//         svg: {
//           height: { md: 20, lg: 40, xl: "40px" },
//           width: { md: 20, lg: 40, xl: "40px" },
//         },
//         left: "60px",
//       }}
//       aria-label="button"
//     >
//       <IconChevronLeft fontSize={25} color={theme.palette.grey[900]} />
//     </IconButton>
//   );
// }

const DashboardLanding = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const homepage = useSelector((state: IRootState) => state.home.home);

  console.log('homepage', homepage?.section_system_metrics?.data)
  console.log('length', homepage?.section_system_metrics?.data.length)
  const navigate = useNavigate();
  const [rotation, setRotation] = useState(55);


  const dispatch: Dispatch<any> = useDispatch();
  useEffect(() => {
    dispatch(getAllHomePage());
  }, []);

  const handleClick = (url: any) => {
    if (url) {
      navigate(url.toLowerCase());
      const searchParams = new URLSearchParams(location.search);
      const productId = searchParams.get("product_id");
      const categoryId = searchParams.get("category_id");
      dispatch(getSingleProduct(Number(productId)));
      dispatch(getSingleCategories(Number(categoryId)));
    }
  };



  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const threshold = 200;
      const minRotation = 0;
      const newRotation = 55 - (scrollPosition / threshold) * 55;

      const clampedRotation = Math.max(newRotation, minRotation);

      setRotation(clampedRotation);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [window.scrollY]);

  return (
    <Container
      sx={{
        minHeight: "100vh",
        marginTop: "50px",
        padding: { xs: 0, md: 4 },
        background: theme.palette.background.paper,
        borderRadius: 2,
        mb: 5
      }}
    >
      {isSmallScreen ? (
        <>
          <Grid
            container
            spacing={7.5}
            justifyContent="center"
            sx={{ px: 1.25, mt: 1, paddingTop: 0 }}
          // padding={2}
          >
            <Grid item xs={12} style={{ paddingTop: 0 }}>
              {/* <Carousel
                showArrows={true}
                showThumbs={false}
                showIndicators={false}
                showStatus={false}
                infiniteLoop={true}
                autoFocus={true}
                emulateTouch={true}
                swipeable={false}
                autoPlay={false}
                interval={5000}
                renderArrowPrev={() => null}
                renderArrowNext={(onClickHandler, hasNext, label) =>
                  hasNext && (
                    <SampleNextArrow
                      onClickHandler={onClickHandler}
                      hasNext={hasNext}
                      label={label}
                    />
                  )
                }
              > */}
              {homepage?.images?.map((items: any, index: number) => {
                return (
                  <Grid
                    container
                    spacing={gridSpacing}
                    style={{ paddingTop: 0 }}
                  >
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={7}
                      lg={7}
                      key={index}
                      sx={{ paddingTop: 0 }}
                    >
                      <Box
                        style={{ cursor: "pointer" }}
                        display="flex"
                        justifyContent="center"
                        key={index}
                      >
                        {items?.image?.data?.map(
                          (item: any, subIndex: number) => (
                            <img
                              key={subIndex}
                              src={`${BASE_URL}${item?.attributes?.url}`}
                              style={{ width: "auto", objectFit: "cover" }}
                              alt={`Image ${subIndex + 1}`}
                            />
                          )
                        )}
                      </Box>
                    </Grid>

                    <Grid item xs={12} sm={12} md={5} lg={5} key={index}>
                      <Typography
                        variant="h1"
                        textAlign="justify"
                        sx={{
                          fontSize: { xs: "2rem", sm: "2.5rem" },
                          fontWeight: 200,
                        }}
                      >
                        {items?.title}
                      </Typography>
                      <Typography
                        variant="h4"
                        textAlign="justify"
                        sx={{
                          pt: 3,
                          lineHeight: 1.5,
                          fontWeight: 200,
                          fontSize: "18px",
                        }}
                      >
                        {items?.description}
                      </Typography>
                      <Stack
                        spacing={1}
                        sx={{ mt: 5 }}
                        direction="row"
                        flexWrap="wrap"
                        justifyContent="center"
                      >
                        {items?.buttons &&
                          items?.buttons?.map((button: any, index: number) => {
                            return (
                              <Button
                                variant="outlined"
                                onClick={() => handleClick(button?.url)}
                              >
                                {button?.name}
                              </Button>
                            );
                          })}
                      </Stack>
                    </Grid>
                  </Grid>
                );
              })}
              {/* </Carousel> */}
            </Grid>
          </Grid>
          {homepage?.section_img_animations?.data[0]?.attributes?.images.length > 0 && (
            <ImageAnimationSection images={homepage?.section_img_animations?.data[0]?.attributes?.images} />
          )
          }
          {homepage?.section_portal_demo &&
            homepage.section_portal_demo.enabled === true && (
              <Box>
                <Typography
                  variant="h1"
                  textAlign="justify"
                  sx={{
                    fontSize: { xs: "1.5rem", sm: "2rem" },
                    fontWeight: 200,
                    lineHeight: 1.5,
                    padding: 3,
                  }}
                >
                  {homepage?.section_portal_demo?.title}
                </Typography>
                <Typography
                  textAlign="justify"
                  variant="body2"
                  sx={{ fontSize: "1rem" }}
                >
                  {homepage?.section_portal_demo?.description}
                </Typography>
                <Card
                  sx={{
                    width: "fit-content",
                    display: "flex",
                    overflow: "visible",
                    alignItems: "center",
                    justifyContent: "center",
                    perspective: "1000px",
                    margin: "20px",
                  }}
                >
                  <Box
                    sx={{
                      width: "100%",
                      height: "100%",
                      position: "relative",
                      transformStyle: "preserve-3d",
                      transition: "transform 0.999s",
                      transform: `rotateX(${rotation}deg)`,
                    }}
                  >
                    <img
                      src={`${BASE_URL}${homepage?.section_portal_demo?.image?.data?.attributes?.url}`}
                      alt="portal"
                      style={{ objectFit: "cover", width: "100%" }}
                    />
                  </Box>
                </Card>

                <Grid container spacing={3} sx={{ mt: 1 }}>
                  {homepage?.section_portal_demo?.feature?.map(
                    (feature: any, index: number) => {
                      return (
                        <Grid item xs={12} sm={12} md={4} lg={4} key={index}>
                          <FadeInWhenVisible>
                            <SubCard
                              sx={{
                                bgcolor: "dark.800",
                                background: theme.palette.background.paper,
                                borderColor: theme.palette.background.paper,
                                "&:hover": { boxShadow: "none" },
                                minHeight: 150,
                              }}
                            >
                              <Stack spacing={2}>
                                <Typography
                                  variant="h2"
                                  sx={{ fontWeight: 500 }}
                                  textAlign="center"
                                  color="primary"
                                >
                                  {feature.title}
                                </Typography>
                                <Typography
                                  variant="body2"
                                  sx={{ fontSize: "1rem" }}
                                  textAlign="justify"
                                >
                                  {feature.description}
                                </Typography>
                              </Stack>
                            </SubCard>
                          </FadeInWhenVisible>
                        </Grid>
                      );
                    }
                  )}
                </Grid>
              </Box>
            )}
          {homepage?.section_start_now &&
            homepage.section_start_now.enabled === true && (
              <Box sx={{ mt: 10, padding: 1 }}>
                <FadeInWhenVisible>
                  <Grid container spacing={gridSpacing}>
                    <Grid item xs={12}>
                      <Typography
                        variant="h1"
                        textAlign="justify"
                        sx={{
                          fontSize: { xs: "1.5rem", sm: "2rem" },
                          fontWeight: 200,
                          lineHeight: 1.5,
                          padding: 2,
                        }}
                      >
                        {homepage?.section_start_now?.title}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography
                        textAlign="justify"
                        variant="body2"
                        sx={{ fontSize: "1rem" }}
                      >
                        {homepage?.section_start_now?.description}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} textAlign="center" sx={{ mt: 1, mb: 1 }}>
                      <Button
                        variant="outlined"
                        onClick={() =>
                          handleClick(
                            homepage?.section_start_now?.start_button.url
                          )
                        }
                        sx={{ fontSize: "1rem", padding: "0.5rem 1.5rem" }}
                      >
                        {homepage?.section_start_now?.start_button.title}
                        <KeyboardArrowRightIcon fontSize="medium" />
                      </Button>
                    </Grid>
                  </Grid>
                </FadeInWhenVisible>
              </Box>
            )}
        </>
      ) : (
        //DESKTOP
        <>
          <Grid
            container
            spacing={7.5}
            justifyContent="center"
            sx={{ px: 1.25, mt: 5 }}
            padding={2}
          >
            <Grid item xs={12} style={{ paddingTop: 0 }}>
              {/* <Carousel
                showArrows={true}
                showThumbs={false}
                showIndicators={true}
                showStatus={false}
                infiniteLoop={true}
                autoFocus={true}
                emulateTouch={true}
                swipeable={true}
                autoPlay={true}
                interval={5000}
                renderArrowPrev={() => null}
                renderArrowNext={(onClickHandler, hasNext, label) =>
                  hasNext && (
                    <SampleNextArrow
                      onClickHandler={onClickHandler}
                      hasNext={hasNext}
                      label={label}
                    />
                  )
                }
              > */}
              {homepage?.images?.map((items: any, index: number) => {
                return (
                  <Grid
                    container
                    spacing={gridSpacing}
                    style={{ paddingTop: 0, marginBottom: 5 }}
                    key={index}
                  >
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={7}
                      lg={7}
                      style={{ paddingTop: 0 }}
                    >
                      <Typography
                        variant="h1"
                        textAlign="start"
                        sx={{
                          fontSize: { xs: "2rem", sm: "2.5rem" },
                          fontWeight: 200,
                        }}
                      >
                        {items?.title}
                      </Typography>
                      <Typography
                        variant="h3"
                        textAlign="justify"
                        sx={{
                          pt: 3,
                          lineHeight: 1.5,
                          fontSize: "18px",
                          fontWeight: 200,
                        }}
                      >
                        {items?.description}
                      </Typography>
                      <Stack
                        spacing={1}
                        sx={{ mt: 5 }}
                        direction="row"
                        flexWrap="wrap"
                      >
                        {items?.buttons &&
                          items?.buttons?.map((button: any, index: number) => {
                            return (
                              <Button
                                variant="contained"
                                key={index}
                                onClick={() => handleClick(button?.url)}
                              >
                                {button?.name}
                              </Button>
                            );
                          })}
                      </Stack>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={5}
                      lg={5}
                      style={{ paddingTop: 0 }}
                    >
                      <Box
                        style={{ cursor: "pointer", margin: "auto" }}
                        display="flex"
                        justifyContent="center"
                        key={index}
                      >
                        {items?.image?.data?.map(
                          (item: any, subIndex: number) => (
                            <img
                              key={subIndex}
                              src={`${BASE_URL}${item?.attributes?.url}`}
                              style={{ width: "60%", objectFit: "cover" }}
                              alt={`Image ${subIndex + 1}`}
                            />
                          )
                        )}
                      </Box>
                    </Grid>
                  </Grid>
                );
              })}
              {/* </Carousel> */}
            </Grid>
          </Grid>
          {homepage?.section_img_animations?.data?.length > 0 && (
            homepage?.section_img_animations?.data?.map((img: any, index: any) => {
              return (
                <ImageAnimationSection key={index} images={img?.attributes?.images} />
              )

            })
          )}
          {/* {homepage?.section_img_animations?.data[0]?.attributes?.images.length > 0 && (
            <ImageAnimationSection images={homepage?.section_img_animations?.data[0]?.attributes?.images} />
          )
          } */}
          {homepage?.section_portal_demo &&
            homepage.section_portal_demo.enabled === true && (
              <Box sx={{ mt: 10 }}>
                <Typography
                  variant="h1"
                  textAlign={{ xs: "justify", sm: "justify", md: "center", lg: "center" }}
                  sx={{
                    fontSize: { xs: "1.5rem", sm: "2rem" },
                    fontWeight: 200,
                    lineHeight: 1.5,
                    padding: 3,
                  }}
                >
                  {homepage?.section_portal_demo?.title}
                </Typography>
                <Typography
                  textAlign={{ xs: "justify", sm: "justify", md: "center", lg: "center" }}
                  variant="body2"
                  sx={{ fontSize: "1rem" }}
                >
                  {homepage?.section_portal_demo?.description}
                </Typography>
                <Card
                  sx={{
                    width: "100%",
                    height: 400,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    perspective: "1000px",
                    margin: "20px",
                  }}
                >
                  <Box
                    sx={{
                      width: "80%",
                      height: "100%",
                      position: "relative",
                      transformStyle: "preserve-3d",
                      transition: "transform 0.999s",
                      transform: `rotateX(${rotation}deg)`
                    }}
                  >
                    <img
                      src={`${BASE_URL}${homepage?.section_portal_demo?.image?.data?.attributes?.url}`}
                      alt="portal"
                      style={{ objectFit: "cover", width: "100%" }}
                    />
                  </Box>
                </Card>
                <Grid container spacing={3} sx={{ mt: 1 }}>
                  {homepage?.section_portal_demo?.feature?.map(
                    (feature: any, index: number) => {
                      return (
                        <Grid item xs={12} sm={12} md={4} lg={4} key={index}>
                          <FadeInWhenVisible>
                            <SubCard
                              sx={{
                                bgcolor: "dark.800",
                                background: theme.palette.background.paper,
                                borderColor: theme.palette.background.paper,
                                "&:hover": { boxShadow: "none" },
                                minHeight: 250,
                              }}
                            >
                              <Stack spacing={2}>
                                <Typography
                                  variant="h2"
                                  sx={{ fontWeight: 500 }}
                                  textAlign="justify"
                                  color="primary"
                                >
                                  {feature.title}
                                </Typography>
                                <Typography
                                  variant="body2"
                                  sx={{ fontSize: "1rem" }}
                                  textAlign="justify"
                                >
                                  {feature.description}
                                </Typography>
                              </Stack>
                            </SubCard>
                          </FadeInWhenVisible>
                        </Grid>
                      );
                    }
                  )}
                </Grid>
              </Box>
            )}

          {homepage?.section_system_metrics?.data.length > 0 && (
            homepage?.section_system_metrics?.data?.map((section: any, index: number) => {
              return (
                <Box key={index} sx={{ padding: 1, mt: 10 }}>
                  <SystemMetricsSection section={section} />
                </Box>
              )
            }))}
          {homepage?.section_start_now &&
            homepage.section_start_now.enabled === true && (
              <Box sx={{ mt: 10 }}>
                <FadeInWhenVisible>
                  <Grid container spacing={gridSpacing}>
                    <Grid item xs={12}>
                      <Typography
                        variant="h1"
                        textAlign={{ xs: "justify", sm: "justify", md: "center", lg: "center" }}
                        sx={{
                          fontSize: { xs: "1.5rem", sm: "2rem" },
                          fontWeight: 200,
                          lineHeight: 1.5,
                          padding: 2,
                        }}
                      >
                        {homepage?.section_start_now?.title}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography
                        textAlign={{ xs: "justify", sm: "justify", md: "center", lg: "center" }}
                        variant="body2"
                        sx={{ fontSize: "1rem" }}
                      >
                        {homepage?.section_start_now?.description}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} textAlign="center" sx={{ mt: 1, mb: 1 }}>
                      <Button
                        variant="contained"
                        onClick={() =>
                          handleClick(
                            homepage?.section_start_now?.start_button.url
                          )
                        }
                        sx={{ fontSize: "1rem", padding: "0.5rem 1.5rem" }}
                      >
                        {homepage?.section_start_now?.start_button.title}
                        <KeyboardArrowRightIcon fontSize="medium" />
                      </Button>
                    </Grid>
                  </Grid>
                </FadeInWhenVisible>
              </Box>
            )}
        </>
      )}
    </Container>
  );
};

export default DashboardLanding;
