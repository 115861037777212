import React from "react";
import { Typography, Box, Stack } from "@mui/material";
import PriceCard from "./pricecard/PriceCard";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useTheme } from "@mui/system";
import MobileCard from "./pricecard/MobileCard";
import MainCard from "../../ui-component/card/MainCard";

const PackagePrice = (product: any) => {
  const theme = useTheme();

  const tableCellStyled = {
    fontWeight: "bolder",
    cursor: "pointer",
    borderBottom: `1px solid rgb(189, 200, 240, 0.2)`,
  };

  const name = product?.product?.attributes?.name;

  return (
    <Box>
      {product && product?.product?.attributes?.package?.data?.length > 0 && (
        <>
          <Box mt={8} mb={5}>
            <Typography variant="h4" textAlign="start">
              {product?.product?.attributes?.name}
            </Typography>
            <Typography variant="subtitle1" textAlign="justify">
              {product?.product?.attributes?.description}
            </Typography>
          </Box>
          <MainCard sx={{ backgroundColor: theme.palette.background.paper }}>
            {product?.product?.attributes?.package?.data[0]?.attributes?.attributes
              ?.length > 3 && !product?.product?.attributes?.package?.data[0]?.attributes?.packageCode.startsWith('OSS') ? (
              <TableContainer>
                <Table sx={{ minWidth: 650 }}>
                  <TableHead>
                    <TableRow>
                      <TableCell align="center" style={tableCellStyled}>
                        vCPUs
                      </TableCell>
                      <TableCell align="center" style={tableCellStyled}>
                        Memory
                      </TableCell>
                      <TableCell align="center" style={tableCellStyled}>
                        Storage
                      </TableCell>
                      <TableCell align="center" style={tableCellStyled}>
                        Bandwidth
                      </TableCell>
                      <TableCell align="center" style={tableCellStyled}>
                        Giá/tháng
                      </TableCell>
                      <TableCell style={tableCellStyled}> </TableCell>
                    </TableRow>
                  </TableHead>
                  <PriceCard
                    pack={product?.product?.attributes?.package?.data}
                    productName={name}
                  />
                </Table>
              </TableContainer>
            ) : (
              <>
                <Stack
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    flexWrap: "wrap",
                  }}
                >
                  <MobileCard
                    pack={product?.product}
                    productName={product?.product?.attributes?.name}
                  />
                </Stack>
              </>
            )}
          </MainCard>
        </>
      )}
    </Box>
  );
};

export default PackagePrice;
