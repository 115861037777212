import axios from "axios";
import { BASE_URL, domain } from "../../api/config";

import { createSlice } from "@reduxjs/toolkit";
import { IContact, ISocial } from "../../api/Service";

interface IService {
  name?: string;
  description?: string;
  contact?: IContact[];
  social?: ISocial[];
  createdAt?: string;
  updatedAt?: string;
  publishedAt?: string;
  logo: {
    data?: {
      id?: string | number;
      attributes?: {
        name?: string;
        alternativeText?: null | string;
        caption?: null | string;
        width?: number;
        height?: number;
        formats?: {
          small?: {
            ext?: string;
            url?: string;
            hash?: string;
            mime?: string;
            name?: string;
            path?: null | string;
            size?: number;
            width?: number;
            height?: number;
          };
          thumbnail?: {
            ext?: string;
            url?: string;
            hash?: string;
            mime?: string;
            name?: string;
            path?: null | string;
            size?: number;
            width?: number;
            height?: number;
          };
        };
        hash?: string;
        ext?: string;
        mime?: string;
        size?: number;
        url?: string;
        previewUrl?: null | string;
        provider?: string;
        provider_metadata?: null | string;
        createdAt?: string;
        updatedAt?: string;
      };
    };
  };
}
export interface InitialService {
  service: IService;
}
export const initialState: InitialService = {
  service: {
    logo: {},
    name: "",
    description: "",
    contact: [],
    social: [],
  },
};
const ServiceSlice = createSlice({
  name: "service",
  initialState: initialState,
  reducers: {
    getServiceSuccess(state, action) {
      state.service = action.payload;
    },
  },
});
export const getAllService = () => {
  return async (dispatch: any) => {
    try {
      const response = await axios.get(
        `${BASE_URL}/api/service-details?filters[domain_service][type][$eq]=${domain}&populate=social&populate=contact&populate=logo`
      );
      dispatch(
        ServiceSlice.actions.getServiceSuccess(response?.data?.data?.[0]?.attributes)
      );
    } catch (error) { }
  };
};
export default ServiceSlice.reducer;
