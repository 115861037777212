const API_TYPE = process.env.REACT_APP_SOURCE_API_TYPE;
const REACT_APP_BACKEND = process.env.REACT_APP_SOURCE_API_URL;

let apiEndpoint;

switch (API_TYPE) {
  case "mockdata":
    apiEndpoint = REACT_APP_BACKEND;
    break;
  case "strapi":
    apiEndpoint = REACT_APP_BACKEND;
    break;
  default:
    console.error("Invalid API_TYPE");
  // return;
}

export const BASE_URL = apiEndpoint;
const getDomain = window.location.hostname;
console.log("getDomain", getDomain);
export const domain = getDomain;
