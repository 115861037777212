import ServiceReducer from "./Slice/Service";
import { combineReducers } from "redux";
import {
  customizationReducer,
  inititalCustomziation,
} from "./customizationReducer";
import AboutPageReducer from "./Slice/PageAbout";

import { InitialService } from "./Slice/Service";
import { InitialAboutPage } from "./Slice/PageAbout";
import { categoryReducer, initialCategory } from "./slices/category";
import { initialProduct, productReducer } from "./slices/product";
import { InitialHomePage } from "./Slice/Home";
import HomePageReducer from "./Slice/Home";
import { InitialPrivacyPage, privacyReducer } from "./Slice/Privacy";

export interface IRootState {
  privacy: InitialPrivacyPage;
  customization: inititalCustomziation;
  service: InitialService;
  about: InitialAboutPage;
  category: initialCategory;
  product: initialProduct;
  home: InitialHomePage;
}

export const reducer = combineReducers({
  customization: customizationReducer,
  service: ServiceReducer,
  about: AboutPageReducer,
  category: categoryReducer,
  product: productReducer,
  home: HomePageReducer,
  privacy: privacyReducer
});
export default reducer;
