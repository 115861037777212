import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IRootState } from "../../store/reducer";
import { Dispatch } from "redux";
import { getAllAboutPage } from "../../store/Slice/PageAbout";
import { BASE_URL } from "../../api/config";
import { gridSpacing } from "../../store/constant";
import { Helmet } from "react-helmet";
import ImageAnimationSection from "../../ui-component/ImageAnimationSection";
import SystemMetricsSection from "../../ui-component/SystemMetricsSection";

const About = () => {
  const aboutpage: any = useSelector((state: IRootState) => state.about.about);
  console.log("aboutpage", aboutpage)
  const dispatch: Dispatch<any> = useDispatch();


  useEffect(() => {
    dispatch(getAllAboutPage());
  }, []);

  return (
    <>
      {aboutpage && (
        <Helmet>
          <meta name="description" content={aboutpage?.content1}></meta>
        </Helmet>
      )}
      <Box sx={{ mt: 3 }}>
        <Box>
          <img
            src={`${BASE_URL}${aboutpage.image1.data?.attributes?.url}`}
            style={{ width: "100%", objectFit: "cover" }}
          />
        </Box>
        <Grid container spacing={gridSpacing} sx={{ padding: 2, mt: 2 }}>
          <Grid item xs={12} sm={12} md={6} lg={6} sx={{ padding: 2 }}>
            <Typography
              variant="h1"
              textAlign="start"
              color="primary"
              sx={{
                marginBottom: "2rem",
              }}
            >
              {aboutpage.title1}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} sx={{ padding: 2 }}>
            <Typography variant="h5" color="inherit" textAlign="justify">
              {aboutpage.content1}
            </Typography>
          </Grid>
        </Grid>
        {aboutpage?.section_img_animations?.data?.length > 0 && (
          aboutpage?.section_img_animations?.data?.map((img: any, index: any) => {
            return (
              <ImageAnimationSection key={index} images={img?.attributes?.images} />
            )

          })
        )}
        {aboutpage.content2 && (
          <Box sx={{ mt: 3 }}>
            <Box>
              <img
                src={`${BASE_URL}${aboutpage.image2.data?.attributes?.url}`}
                style={{ width: "100%", objectFit: "cover" }}
              />
            </Box>
            <Grid container spacing={gridSpacing} sx={{ padding: 2, mt: 2 }}>
              <Grid item xs={12} sm={12} md={6} lg={6} sx={{ padding: 1 }}>
                <Typography
                  variant="h1"
                  color="primary"
                  textAlign="start"
                  sx={{
                    marginBottom: "2rem",
                  }}
                >
                  {aboutpage.title2}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} sx={{ padding: 2 }}>
                <Typography variant="h5" color="inherit" textAlign="justify">
                  {aboutpage.content2}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        )}
      </Box>
      {aboutpage?.section_system_metrics?.data?.length > 0 && (
        aboutpage?.section_system_metrics?.data?.map((section: any, index: number) => {
          return (
            <Box key={index} sx={{ padding: 1, mt: 10, mb: 5 }}>
              <SystemMetricsSection section={section} />
            </Box>
          )
        })
      )}
    </>
  );
};

export default About;
