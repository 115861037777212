
import React from "react";

// material-ui
import { useTheme } from "@mui/material/styles";
import { CardHeader, CardContent, Typography, Divider, CardContentProps, CardProps, CardHeaderProps } from "@mui/material";
import Card from "@mui/material/Card";
import { KeyedObject } from '../../types';
// constant
const headerSX = {
  "& .MuiCardHeader-action": { mr: 0 },
};


export interface MainCardProps extends KeyedObject {
  border?: boolean;
  boxShadow?: boolean;
  children: React.ReactNode | string;
  style?: React.CSSProperties;
  content?: boolean;
  className?: string;
  contentClass?: string;
  contentSX?: CardContentProps['sx'];
  darkTitle?: boolean;
  sx?: CardProps['sx'];
  secondary?: CardHeaderProps['action'];
  shadow?: string;
  elevation?: number;
  title?: React.ReactNode | string;
}


// ==============================|| CUSTOM MAIN CARD ||============================== //

const MainCard = React.forwardRef(
  (
    {
      border = false,
      boxShadow,
      children,
      content = true,
      contentClass = "",
      contentSX = {},
      darkTitle,
      secondary,
      shadow,
      sx = {},
      title,
      ...others
    }: MainCardProps,
    ref: any
  ) => {
    const theme: any = useTheme();

    return (
      <Card
        ref={ref}
        {...others}
        sx={{
          backgroundColor: theme.palette.background.default,
          border: border ? "1px solid" : "none",
          borderColor:
            theme.palette.mode === "dark"
              ? theme.palette.background.default
              : theme.palette.grey[300] + 98,
          ":hover": {
            boxShadow: boxShadow
              ? shadow ||
              (theme.palette.mode === "dark"
                ? "0 2px 14px 0 rgb(33 150 243 / 10%)"
                : "0 2px 14px 0 rgb(32 40 45 / 8%)")
              : "inherit",
          },
          ...sx,
        }}
      >
        {/* card header and action */}
        {!darkTitle && title && (
          <CardHeader
            sx={headerSX}
            title={title as string}
            action={secondary as string}
          />
        )}
        {darkTitle && title && (
          <CardHeader
            sx={headerSX}
            title={<Typography variant="h3">{title as string}</Typography>}
            action={secondary as string}
          />
        )}

        {/* content & header divider */}
        {title && <Divider />}

        {/* card content */}
        {content && (
          <CardContent sx={contentSX} className={contentClass}>
            {children}
          </CardContent>
        )}
        {!content && children}
      </Card>
    );
  }
);
// MainCard.propTypes = {
//   border: PropTypes.bool,
//   boxShadow: PropTypes.bool,
//   children: PropTypes.node,
//   content: PropTypes.bool,
//   contentClass: PropTypes.string,
//   contentSX: PropTypes.object,
//   darkTitle: PropTypes.bool,
//   secondary: PropTypes.oneOfType([
//     PropTypes.node,
//     PropTypes.string,
//     PropTypes.object,
//   ]),
//   shadow: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
//   sx: PropTypes.object,
//   title: PropTypes.oneOfType([
//     PropTypes.node,
//     PropTypes.string,
//     PropTypes.object,
//   ]),
// };

export default MainCard;
