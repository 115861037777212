
import React, { useEffect, useState } from "react";
import Routes from "./routes";
import ScrollToTopOnPageChange from "./ui-component/ScrollToTop";
import { useSelector } from "react-redux";
import { IRootState } from "./store/reducer";
import { BASE_URL } from "./api/config";
import { dispatch } from "./store";
import { getAllService } from "./store/Slice/Service";

function App() {

  const service = useSelector((state: IRootState) => state.service.service);

  useEffect(() => {
    dispatch(getAllService());
  }, []);

  useEffect(() => {
    if (service) {
      const faviconUrl = `${BASE_URL}${service?.logo?.data?.attributes?.url}`;
      const title = service?.name || "";
      const description = service?.description || "";
      const noscript = service?.description || "";
      updateFavicon(faviconUrl, title, description, noscript);
    }
  }, [service]);

  const updateFavicon = (
    faviconUrl: string,
    title: string,
    description: string,
    noscript: string
  ) => {
    if (service && service.logo && service.logo.data && service.logo.data.attributes && service.logo.data.attributes.url) {
      // Remove existing favicon
      const existingFavicon = document.querySelector("link[rel='icon']");
      if (existingFavicon) {
        existingFavicon.remove();
      }

      // Create new link element for favicon
      const newFavicon = document.createElement("link");
      newFavicon.rel = "icon";
      newFavicon.href = faviconUrl;
      // Append new favicon to the head
      document.head.appendChild(newFavicon);

      // Update title
      document.title = title;

      // Update meta description
      const existingMetaDescription = document.querySelector(
        "meta[name='description']"
      );
      if (existingMetaDescription) {
        existingMetaDescription.setAttribute("content", description);
      } else {
        const newMetaDescription = document.createElement("meta");
        newMetaDescription.name = "description";
        newMetaDescription.content = description;
        document.head.appendChild(newMetaDescription);
      }

      // Update noscript tag content
      const noscriptContent = document.querySelector("noscript");
      if (noscriptContent) {
        noscriptContent.innerHTML = description;
      } else {
        const newNoscript = document.createElement("noscript");
        newNoscript.innerHTML = noscript;
        document.body.appendChild(newNoscript);
      }
    }
  };

  return (
    <div>
      <ScrollToTopOnPageChange />
      <Routes />
    </div>
  );
}

export default App;
