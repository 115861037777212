import React, { useRef, useState } from "react";
import {
  Box,
  Avatar,
  Typography,
  Button,
  useMediaQuery,
  Stack,
  Popper,
  Chip,
  ClickAwayListener,
  Paper,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Divider,
  List,
} from "@mui/material";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useTheme } from "@mui/material/styles";
import avatar from "../../../assets/images/avatar.png";
import { useKeycloak } from "../../../hooks/useKeycloak";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import Transitions from "../../../ui-component/Transitions";
import MainCard from "../../../ui-component/card/MainCard";
import useConfig from "../../../hooks/useConfig";
import { IconLogout, IconSettings, IconUser } from "@tabler/icons";
import { decodeUserInfo } from "../../../keycloak/jwt";


function Login() {
  const theme = useTheme();
  const navigate = useNavigate();
  const anchorRef = useRef<any>(null);
  const matchMobile = useMediaQuery(theme.breakpoints.down("md"));
  const accessToken = Cookies.get("access_token");

  const { borderRadius } = useConfig();

  const [open, setOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const { login, logout, register }: any = useKeycloak();
  const PORTAL_URL = process.env.REACT_APP_PORTAL_URL;

  const user = decodeUserInfo(accessToken!);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (
    event: React.MouseEvent<HTMLDivElement> | MouseEvent | TouchEvent
  ) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };


  return (
    <>
      {accessToken ? (
        <>
          <Chip
            sx={{
              height: "48px",
              alignItems: "center",
              borderRadius: "27px",
              transition: "all .2s ease-in-out",
              backgroundColor: theme.palette.background.paper,
              borderColor: theme.palette.background.paper,
              '&[aria-controls="menu-list-grow"], &:hover': {
                borderColor: "rgba(42, 22, 111, 0.8)",
                background: "rgba(42, 22, 111, 0.8) !important",
                color: "#e3f2fd",
                "& svg": {
                  stroke: theme.palette.primary.light,
                },
              },
              "& .MuiChip-label": {
                lineHeight: 0,
              },
            }}
            icon={
              <Avatar
                src={avatar}
                alt="user-images"
                sx={{
                  ...theme.typography.caption,
                  width: 32, height: 32,
                  margin: "8px 0 8px 8px !important",
                  cursor: "pointer"
                }}
                ref={anchorRef}
                aria-controls={open ? "menu-list-grow" : undefined}
                aria-haspopup="true"
                color="inherit"
              />
            }
            label={
              <IconSettings stroke={1.5} size="24px" color="#2196f3" />
            }
            variant="outlined"
            ref={anchorRef}
            aria-controls={open ? "menu-list-grow" : undefined}
            aria-haspopup="true"
            onClick={handleToggle}
            color="default"
            aria-label="user-account"
          />

          <Popper
            placement="bottom"
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            transition
            disablePortal
            modifiers={[
              {
                name: "offset",
                options: {
                  offset: [0, 14],
                },
              },
            ]}
          >
            {({ TransitionProps }) => (
              <ClickAwayListener onClickAway={handleClose}>
                <Transitions in={open} {...TransitionProps}>
                  <Paper>
                    {open && (
                      <MainCard
                        sx={{
                          backgroundColor: theme.palette.background.paper,
                        }}
                        border={false}
                        elevation={16}
                        content={false}
                        boxShadow
                        shadow={theme.shadows[16]}
                      >

                        <PerfectScrollbar
                          style={{
                            height: "100%",
                            maxHeight: "calc(100vh - 250px)",
                            overflowX: "hidden",
                          }}
                        >

                          <Box sx={{ p: 2, pt: 0 }}>
                            <List
                              component="nav"
                              sx={{
                                width: "100%",
                                maxWidth: 350,
                                minWidth: 300,
                                backgroundColor:
                                  theme.palette.background.paper,
                                borderRadius: "10px",
                                [theme.breakpoints.down("md")]: {
                                  minWidth: "100%",
                                },
                                "& .MuiListItemButton-root": {
                                  mt: 0.5,
                                },
                              }}
                            >
                              <Box sx={{ p: 2, pb: 0, mb: 2 }}>
                                <Stack>
                                  <Stack
                                    direction="row"
                                    spacing={0.5}
                                    alignItems="center"
                                  >
                                    <Typography
                                      component="span"
                                      variant="h4"
                                      sx={{ fontWeight: 500, }}
                                    >
                                      Xin chào {user?.username}
                                    </Typography>
                                  </Stack>
                                </Stack>

                              </Box>
                              <Divider />
                              <ListItemButton
                                sx={{ borderRadius: `${borderRadius}px` }}
                                selected={selectedIndex === 4}
                                component="a"
                                href={`${PORTAL_URL}?token=${accessToken}`}
                                target="_blank"
                              >
                                <ListItemIcon>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="icon icon-tabler icon-tabler-layout-dashboard"
                                    width="20"
                                    height="20"
                                    viewBox="0 0 24 24"
                                    stroke-width="1.5"
                                    stroke="#ffffff"
                                    fill="none"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  >
                                    <path
                                      stroke="none"
                                      d="M0 0h24v24H0z"
                                      fill="none"
                                    />
                                    <path d="M4 4h6v8h-6z" />
                                    <path d="M4 16h6v4h-6z" />
                                    <path d="M14 12h6v8h-6z" />
                                    <path d="M14 4h6v4h-6z" />
                                  </svg>
                                </ListItemIcon>
                                <ListItemText
                                  primary={
                                    <Typography variant="body2">
                                      Console Portal
                                    </Typography>
                                  }
                                />
                              </ListItemButton>

                              <ListItemButton
                                sx={{ borderRadius: `${borderRadius}px` }}
                                selected={selectedIndex === 4}
                                onClick={logout}
                              >
                                <ListItemIcon>
                                  <IconLogout
                                    stroke={1.5}
                                    size="22px"
                                    color="#ffffff"
                                  />
                                </ListItemIcon>
                                <ListItemText
                                  primary={
                                    <Typography variant="body2">
                                      Đăng xuất
                                    </Typography>
                                  }
                                />
                              </ListItemButton>
                            </List>
                          </Box>
                        </PerfectScrollbar>
                      </MainCard>
                    )}
                  </Paper>
                </Transitions>
              </ClickAwayListener>
            )}
          </Popper>
        </>
      ) : (
        <>
          <Box marginRight={2} onClick={login}>
            <Button variant="contained">Đăng nhập</Button>
          </Box>
          {/* <Box > */}
          <Button variant="text" onClick={register}>Đăng ký</Button>
          {/* </Box> */}
        </>
      )}
    </>
  )
}

export default Login