import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../../api/config";

export interface IPrivacyPage {
  title: string;
  content: string;
}
export interface InitialPrivacyPage {
  privacy: IPrivacyPage;
}
export const initialState: InitialPrivacyPage = {
  privacy: {
    title: "",
    content: "",
  },
};

const slice = createSlice({
  name: "privacy",
  initialState: initialState,
  reducers: {
    getPrivacySuccess(state, action) {
      state.privacy = action.payload;
    },
  },
});


export const getPrivacyPage = () => {
  return async (dispatch: any) => {
    try {
      const response = await axios.get(`${BASE_URL}/api/term-and-privacy`);
      dispatch(
        slice.actions.getPrivacySuccess(response.data.data.attributes)
      );
    } catch (error) {
      console.error(error);
    }
  }
}

export const privacyReducer = slice.reducer;
