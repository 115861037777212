import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL, domain } from "../../api/config";

export interface IAboutPage {
  title1: string;
  content1: string;
  image1: string;
  title2: string;
  content2: string;
  image2: string;
}
export interface InitialAboutPage {
  about: IAboutPage;
}
export const initialState: InitialAboutPage = {
  about: {
    title1: "",
    content1: "",
    image1: "",
    title2: "",
    content2: "",
    image2: "",
  },
};

const AboutPageSlice = createSlice({
  name: "about",
  initialState: initialState,
  reducers: {
    getAboutSuccess(state, action) {
      state.about = action.payload;
    },
  },
});

export const getAllAboutPage = () => {
  return async (dispatch: any) => {
    try {
      const response = await axios.get(`${BASE_URL}/api/about-pages?filters[domain_service][type][$eq]=${domain}&populate=section_img_animations.images.image&populate=image1&populate=image2&populate=section_system_metrics&populate=section_system_metrics.metrics&populate=section_system_metrics.features&populate=section_system_metrics.features.icon`);
      dispatch(
        AboutPageSlice.actions.getAboutSuccess(response?.data?.data?.[0]?.attributes)
      );
    } catch (error) {
      console.error(error);
    }
  };
};


export default AboutPageSlice.reducer;
