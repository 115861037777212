
import PropTypes from "prop-types";

// material-ui
import { useTheme } from "@mui/material/styles";
import { Divider, List, Typography, Box } from "@mui/material";

// project imports
import NavItem from "../NavItem";
import React from "react";

// ==============================|| SIDEBAR MENU LIST GROUP ||============================== //
interface props {
  item: any;
}

const NavGroup: React.FC<props> = ({ item }) => {

  const theme = useTheme();

  // menu list items
  const items = item.children?.map((menu: any) => {
    return <NavItem key={menu.id} item={menu} category={item} />;
  });

  return (
    <>
      <List
        subheader={
          item.title && (
            <>
              <Box sx={{ display: "flex", alignItems: "center", justifyContent: "start", padding: 2, textAlign: "center" }}>
                <Typography
                  variant="caption"
                  sx={{ ...theme.typography.h4, marginLeft: 1 }}
                  textAlign="center"
                  gutterBottom
                >
                  {item.title}
                </Typography>
              </Box>
            </>
          )
        }
      >
        {items}
      </List>

      {/* group divider */}
      <Divider sx={{ mt: 0.25, mb: 1.25 }} />
    </>
  );
};

NavGroup.propTypes = {
  item: PropTypes.object,
};

export default NavGroup;
