import React from 'react';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import CategoryTab from '../pricing/TabPanel';

function Pricing() {
  return (
    <Container sx={{ minHeight: "100vh", mt: 5 }}>
      <Box
        sx={{
          padding: 2,
          width: '100%',
          minHeight: '100vh',
          borderRadius: 2
        }}
      >
        <CategoryTab />
      </Box>
    </Container>
  )
}

export default Pricing

