// material-ui
import { useTheme } from "@mui/material/styles";
import {
  CardMedia,
  Container,
  Grid,
  Box,
  Stack,
  Typography,
} from "@mui/material";

// third party
import { motion } from "framer-motion";

// assets
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { ProductDetailProps } from "./HeaderSection";
import { BASE_URL } from "../../../api/config";

// ==============================|| LANDING - CUSTOMIZE ||============================== //

const CustomizeSection: React.FC<ProductDetailProps> = ({ productDetail }) => {
  const theme = useTheme();
  const listSX = {
    fontSize: "1rem",
    color: theme.palette.grey[900],
    ml: 2,
    svg: { color: theme.palette.primary.main },
    textAlign: "justify",
  };

  const sortSection = [...(productDetail?.attributes?.Section || [])].sort(
    (a: any, b: any) => {
      const orderA = a?.order || 0;
      const orderB = b?.order || 0;
      return orderA - orderB;
    }
  );

  return (
    <Container
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        m: { xs: 0, md: 3 },
      }}
    >
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        sx={{ paddingRight: 0 }}
        spacing={{ xs: 1.5, sm: 2.5, md: 3, lg: 5 }}
      // key={productDetail?.id}
      >
        {productDetail?.attributes?.Section &&
          sortSection?.map((item: any, index: number) => {
            const url = item?.image?.data?.map(
              (item: any) => item?.attributes?.url
            );
            const image = url ? `${BASE_URL}${url[0]}` : "";
            const isEvenIndex = index % 2 === 0;
            const isSmallScreen = theme.breakpoints.down("sm");
            return (
              <>
                <Stack
                  alignItems="center"
                  direction={{
                    xs: "column",
                    md: isEvenIndex ? "row" : "row-reverse",
                  }}
                  justifyContent="center"
                  key={index}
                >
                  <Grid
                    item
                    xs={12}
                    md={6}
                    sx={{
                      img: { width: "100%" },
                    }}
                  >
                    <motion.div
                      initial={{ opacity: 0, translateY: 550 }}
                      animate={{ opacity: 1, translateY: 0 }}
                      transition={{
                        type: "spring",
                        stiffness: 150,
                        damping: 30,
                      }}
                    >
                      <Box sx={{ width: "100%", mb: 5, mx: "auto", border: "0.1px solid rgba(128,128,128,0.2)", boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.3)", borderRadius: 5, padding: 1 }}>
                        <CardMedia
                          component="img"
                          image={image}
                          alt="x-or cloud"
                        />
                      </Box>
                    </motion.div>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={6}
                    key={item?.id}
                    sx={{
                      flexDirection: isSmallScreen ? "row" : "row-reverse",
                      order: { md: isEvenIndex ? 1 : 2, xs: 2 },
                      padding: 2,
                    }}
                  >
                    <Grid container spacing={2.5}>
                      <Grid item xs={12} md={12}>
                        <motion.div
                          initial={{ opacity: 0, translateY: 550 }}
                          animate={{ opacity: 1, translateY: 0 }}
                          transition={{
                            type: "spring",
                            stiffness: 150,
                            damping: 30,
                          }}
                        >
                          <Typography
                            variant="h5"
                            textAlign="justify"
                            sx={{
                              fontSize: { xs: "1.5rem", sm: "2.125rem" },
                              mb: 2,
                            }}
                            color="rgb(33, 150, 243)"
                          >
                            {item?.title}
                          </Typography>
                        </motion.div>
                        <motion.div
                          initial={{ opacity: 0, translateY: 550 }}
                          animate={{ opacity: 1, translateY: 0 }}
                          transition={{
                            type: "spring",
                            stiffness: 150,
                            damping: 30,
                          }}
                        >
                          <Typography
                            variant="subtitle2"
                            color="text.primary"
                            textAlign="justify"
                            sx={{
                              fontSize: "1rem",
                              zIndex: "99"
                            }}
                          >
                            {item?.subtitle}
                          </Typography>
                        </motion.div>
                      </Grid>
                      {item?.content?.map((content: any, index: number) => {
                        return (
                          <Grid
                            item
                            xs={12}
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "start",
                              alignItems: isSmallScreen ? "center" : "start"
                            }}
                            key={index}
                          >
                            <Box>
                              <CheckCircleOutlineIcon
                                fontSize="medium"
                                sx={{ color: "#e67e19" }}
                              />
                            </Box>
                            <Box>
                              <Typography sx={listSX}>
                                {content?.content}
                              </Typography>
                            </Box>
                          </Grid>
                        );
                      })}
                    </Grid>
                  </Grid>
                </Stack>
              </>
            );
          })}
      </Grid>
    </Container>
  );
};

export default CustomizeSection;
