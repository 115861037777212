import { Box, Stack } from "@mui/material";
import { BASE_URL } from "../api/config";

const ImageAnimationSection = (images: any) => {
  return (
    <Box sx={{ mt: 5, mb: 5 }}>
      <Box sx={{ overflow: "hidden", padding: "60px 0", whiteSpace: "nowrap" }}>
        <Box
          sx={{
            whiteSpace: "nowrap",
            animation: `40s imageSlide infinite linear`,
            display: "inline-block",
          }}
        >
          {images.images.map((image: any, index: number) => {
            const imgUrl = `${BASE_URL}${image?.image?.data?.attributes?.url}`;
            return (
              <img
                key={index}
                src={imgUrl}
                alt={`x-or.cloud ${index} image`}
                style={{
                  height: "60px",
                  margin: "0 40px",
                  filter:
                    "drop-shadow(rgb(130, 78, 220) 0px 0px 5px) drop-shadow(rgb(130, 78, 220) 0px 0px 10px)",
                  opacity: 1,
                }}
              />
            );
          })}
        </Box>
        <Box
          sx={{
            whiteSpace: "nowrap",
            animation: `40s imageSlide infinite linear`,
            display: "inline-block",
          }}
        >
          {images.images.map((image: any, index: number) => {
            const imgUrl = `${BASE_URL}${image?.image?.data?.attributes?.url}`;
            return (
              <img
                key={index}
                src={imgUrl}
                alt={`x-or.cloud ${index} image`}
                style={{
                  height: "60px",
                  margin: "0 40px",
                  filter:
                    "drop-shadow(rgb(130, 78, 220) 0px 0px 5px) drop-shadow(rgb(130, 78, 220) 0px 0px 10px)",
                  opacity: 0.8,
                }}
              />
            );
          })}
        </Box>
      </Box>
      <style>
        {`
                  @keyframes imageSlide {
                  from { transform: translateX(0); }
                  to { transform: translateX(-100%); }
                }`}
      </style>
    </Box>
  );
};

export default ImageAnimationSection;
