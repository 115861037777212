import { createSlice } from "@reduxjs/toolkit";
import productApi from "../../api/productApi";
interface Highlight {
  id: number;
  title?: string;
  description?: string;
  icon: {
    data?: null | unknown;
  };
}
interface UsecaseAttributes {
  title?: string;
  createdAt: string;
  updatedAt: string;
  publishedAt?: string;
}

interface Usecase {
  id?: number;
  attributes: UsecaseAttributes;
}
export interface ComparePrice {
  id: number;
  name: string;
  price: number;
}
export interface initialProduct {
  error: boolean;
  isLoading: boolean;
  relatedProduct: {
    id?: number;
    attributes?: {
      name: string;
      link: string;
      image: {
        data: {
          attributes: {
            url: string;
          };
        };
      };
    };
  }[];
  product: {
    id?: number;
    attributes?: {
      name?: string;
      createdAt?: string;
      updatedAt?: string;
      publishedAt?: string;
      slug?: string;
      description?: string;
      title_highlight?: string;
      highlight?: Highlight[];
      section_img_animations: any;
      section_system_metrics: any;
      blog_image_header?: {
        data?: {
          attributes: {
            url: string;
          };
        };
      };
      usecases?: {
        data: Usecase[];
      };
      package?: {
        data?: [];
      };
      compare_prices?: ComparePrice[];
      video?: string | null;
    };
  };
}

const localStorageState = localStorage.getItem("reduxState");
const initialState: initialProduct = localStorage.getItem("reduxState")
  ? JSON.parse(localStorageState || "{}")
  : {
    error: false,
    isLoading: false,
    relatedProduct: {},
    product: {},
  };

const slice = createSlice({
  name: "product",
  initialState,
  reducers: {
    hasError(state, action) {
      state.error = action.payload;
    },
    getProductSuccess(state, action) {
      state.product = action.payload;
    },
    getRelatedProductSuccess(state, action) {
      state.relatedProduct = action.payload;
    },
  },
});

export function getSingleProduct(id: number) {
  return async (dispatch: any) => {
    try {
      const response = await productApi.getSingleProductById(id);
      localStorage.setItem(
        "reduxState",
        JSON.stringify({
          error: false,
          isLoading: false,
          product: { ...response.data.data },
        })
      );
      dispatch(slice.actions.getProductSuccess(response.data.data));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getRelatedProduct() {
  return async (dispatch: any) => {
    try {
      const response = await productApi.getRelatedProduct();
      dispatch(slice.actions.getRelatedProductSuccess(response.data.data));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export const productReducer = slice.reducer;
