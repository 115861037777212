import { JwtPayload, jwtDecode } from "jwt-decode";

interface UserInfo {
  userId: string;
  username: string;
}

export const isValidToken = (access_token: string) => {
  if (!access_token) return false;
  const decode = jwtDecode(access_token);

  // Check if decoding was successful
  if (decode !== null) {
    const currentTime = Date.now() / 1000;
    decode.exp = decode?.exp ? decode.exp : 0;
    // Check if the expiration time is greater than the current time
    return decode.exp > currentTime;
  }

  // Handle the case where decoding failed (decode is null)
  return false;
}


export const decodeUserInfo = (access_token: string) => {
  if (!access_token) return null;

  try {
    // Decode the JWT
    const decoded: any = jwtDecode(access_token);

    // Extract user information from the decoded token
    const userInfo: UserInfo = {
      username: decoded.preferred_username, 
      userId: decoded.sub
    };

    return userInfo;
  } catch (error) {
    console.error('Error decoding access token:', error);
    return null;
  }
}