import { useEffect, useRef, useState } from "react";

// material-ui
import { useTheme } from "@mui/material/styles";
import {
  AppBar,
  Box,
  IconButton,
  ClickAwayListener,
  Grid,
  Paper,
  Popper,
  Toolbar,
  useMediaQuery,
  Button,
} from "@mui/material";

// project imports

import Transitions from "../../../../ui-component/Transitions";

// assets
import { IconDotsVertical } from "@tabler/icons";
import { useNavigate } from "react-router-dom";
import { useKeycloak } from "../../../../hooks/useKeycloak";
import { decodeUserInfo } from "../../../../keycloak/jwt";
import Cookies from "js-cookie";
import { gridSpacing } from "../../../../store/constant";

// ==============================|| MOBILE HEADER ||============================== //

const MobileSection = () => {
  const theme = useTheme();
  const { login, logout, register }: any = useKeycloak();
  const matchMobile = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();
  const accessToken = Cookies.get("access_token");
  const [open, setOpen] = useState(false);

  const user = decodeUserInfo(accessToken!);

  const anchorRef = useRef<any>(null);

  const handleNavigatePricing = () => {
    navigate("/pricing");
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: MouseEvent | TouchEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <>
      <Box component="span" ref={anchorRef} sx={{ mt: 1, ml: 1 }}>
        <IconButton
          sx={{
            color: theme.palette.mode === "dark" ? "primary.main" : "inherit",
            ml: 0.5,
            cursor: "pointer",
          }}
          onClick={handleToggle}
        >
          <IconDotsVertical
            stroke={1.5}
            aria-controls={open ? "menu-list-grow" : undefined}
            aria-haspopup="true"
            style={{ fontSize: "1.5rem" }}
          />
        </IconButton>
      </Box>

      <Popper
        placement="bottom-end"
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        style={{ width: "100%", zIndex: 1 }}
        modifiers={[
          {
            name: "offset",
            options: {
              offset: [0, matchMobile ? 30 : 10],
            },
          },
        ]}
      >
        {({ TransitionProps }) => (
          <ClickAwayListener onClickAway={handleClose}>
            <Transitions
              type="zoom"
              in={open}
              {...TransitionProps}
              sx={{ transformOrigin: "top right" }}
            >
              <Paper>
                {open && (
                  <AppBar
                    color="inherit"
                    sx={{
                      [theme.breakpoints.down("md")]: {
                        background: theme.palette.background.default,
                      },
                    }}
                  >
                    <Toolbar sx={{ pt: 2.75, pb: 2.75 }}>
                      <Grid container alignItems="center" spacing={gridSpacing}>
                        <Grid item xs={12} md={12} sm={12} lg={12}>
                          <Button
                            variant="outlined"
                            onClick={() => navigate("/")}
                            style={{ width: "90vw" }}
                          >
                            Trang chủ
                          </Button>
                        </Grid>

                        <Grid item xs={12}>
                          <Button
                            variant="outlined"
                            onClick={handleNavigatePricing}
                            style={{ width: "90vw" }}
                          >
                            Bảng giá
                          </Button>
                        </Grid>
                        <Grid item xs={12}>
                          <Button
                            variant="outlined"
                            href="https://docs.x-or.cloud/"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ width: "90vw" }}
                          >
                            Tài liệu
                          </Button>
                        </Grid>
                        {accessToken ? (
                          <div></div>
                        ) : (
                          <>
                            <Grid item xs={12}>
                              <Button
                                variant="outlined"
                                style={{ width: "90vw" }}
                                onClick={login}
                              >
                                Đăng nhập
                              </Button>
                            </Grid>
                            {/* </Box> */}
                            <Grid item xs={12}>
                              <Button
                                variant="outlined"
                                style={{ width: "90vw" }}
                                onClick={register}
                              >
                                Đăng ký
                              </Button>
                            </Grid>
                          </>
                        )}
                      </Grid>
                    </Toolbar>
                  </AppBar>
                )}
              </Paper>
            </Transitions>
          </ClickAwayListener>
        )}
      </Popper>
    </>
  );
};

export default MobileSection;
