import MainLayout from "../layout/MainLayout.tsx";
import About from "../views/pages/About";

import Pricing from "../views/pages/Pricing";
import ContactUsPage from "../views/contact-sales/ContactUsPage"

import Privacy from "../views/pages/Privacy";

import NotFoundPage from "../views/pages/NotFoundPage";

import ProductPage from "../views/pages/ProductPage";
import BlogPage from "../views/pages/BlogPage";
import DashboardLanding from "../views/pages/Landing/Dashboard";


interface Route {
  path: string;
  element?: React.ReactElement;
  children?: Route[];
}

const MainRoutes: Route = {
  path: "/",
  element: <MainLayout />,
  children: [

    {
      path: "/",
      element: <DashboardLanding />,
    },
    {
      path: "/:product",
      element: <ProductPage />,
    },
    {
      path: "/blog/:product",
      element: <BlogPage />
    },
    {
      path: "/about",
      element: <About />,
    },
    {
      path: "/contact",
      element: <ContactUsPage />,
    },
    {
      path: "/pricing",
      element: <Pricing />,
    },
    {
      path: "/privacy",
      element: <Privacy />,
    },
    {
      path: "*",
      element: <NotFoundPage />,
    },
  ],
};

export default MainRoutes;