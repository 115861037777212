import { createSlice } from "@reduxjs/toolkit";
import categoryApi from "../../api/categoryApi";

// project imports
// import axios from 'utils/axios';

export interface Icategory {
  id: string;
  name: string;
  icon: string;
  url: string;
  attributes: any;
  data: any;
}
interface IconDataSmalMenu {
  id: string;
  attributes: {
    url: string;
  };
}
interface IMenuProduct {
  id: string;
  attributes: {
    name: string;
    url: string;
  };
}
export interface IDataProduct {

  id: number | string;
  attributes: {
    icon: {
      data: IconDataSmalMenu[];
    };
    products: {
      data: IMenuProduct[];
    };
  };
}
export interface initialCategory {
  category: any;
  error: boolean;
  isLoading: boolean;
  categories: Icategory[];
  categoryById: Icategory;
  listProduct: {
    id: string;
    attributes: {
      icon: {
        data: IconDataSmalMenu[];
      };
      products: {
        data: IMenuProduct[];
      };
    };
  };
}

export const initialState: initialCategory = {
  error: false,
  isLoading: false,
  categories: [],
  categoryById: {
    id: "",
    name: "",
    icon: "",
    url: "",
    attributes: "",
    data: "",
  },
  listProduct: {
    id: "",
    attributes: {
      icon: {
        data: [{ id: "", attributes: { url: "" } }],
      },
      products: {
        data: [{ id: "", attributes: { url: "", name: "" } }],
      },
    },
  },
  category: undefined
};

const slice = createSlice({
  name: "category",
  initialState,
  reducers: {
    //HAS ERROR
    hasError(state, action) {
      state.error = action.payload;
    },
    getCategoriesSuccess(state, action) {
      state.categories = action.payload;
    },
    getSingleCategoriesSuccess(state, action) {
      state.categoryById = action.payload;
    },
    getListProductSuccess(state, action) {
      state.listProduct = action.payload;
    },
  },
});

// -------------------------------------

export function getCategories() {
  return async (dispatch: any) => {
    try {
      const response = await categoryApi.getAll();
      dispatch(slice.actions.getCategoriesSuccess(response.data.data));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getSingleCategories(id: number | string) {
  return async (dispatch: any) => {
    try {
      const response = await categoryApi.getById(id);
      dispatch(slice.actions.getSingleCategoriesSuccess(response.data.data));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getAllById(id: number) {
  return async (dispatch: any) => {
    try {
      const response = await categoryApi.getAllById(id);
      dispatch(slice.actions.getListProductSuccess(response.data.data));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export const categoryReducer = slice.reducer;

// Reducer
