import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// material-ui
import { Typography } from "@mui/material";

// project import
import MainCard from "./MainCard";
import FadeInWhenVisible from "../Animation";
import { dispatch } from "../../store";
import { getSingleProduct } from "../../store/slices/product";
import { getSingleCategories } from "../../store/slices/category";
import { LazyLoadImage } from "react-lazy-load-image-component";

// ==============================|| PRODUCT CARD ||============================== //

const UseCaseCard = ({
  title,
  icon,
  des,
  url,
}: {
  title: string;
  icon: any;
  des: string;
  url: string;
}) => {
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(false);
  }, []);

  const handleClick = () => {
    if (url !== null) {
      navigate(url);

      // Extracting query parameters from the URL
      const searchParams = new URLSearchParams(url.toString());
      const productId = searchParams.get("product_id");
      const categoryId = searchParams.get("category_id");

      const fetchData = async () => {
        if (productId) {
          await dispatch(getSingleProduct(Number(productId)));
        }

        if (categoryId) {
          await dispatch(getSingleCategories(Number(categoryId)));
        }
      };

      fetchData();
    }
  };

  return (
    <>
      {isLoading ? (
        <div>Loading ....</div>
      ) : (
        <FadeInWhenVisible>
          <MainCard
            content={false}
            boxShadow
            sx={{
              padding: 3,
              "&:hover": {
                transform: "scale3d(1.02, 1.02, 1)",
                transition: "all .4s ease-in-out",
              },
              minHeight: 250,
            }}
            onClick={handleClick}
          >
            <LazyLoadImage
              src={icon}
              width="20%"
              alt="icon"
              style={{ objectFit: "cover" }}
            />
            <Typography
              variant="h4"
              color="primary"
              sx={{
                overflow: "hidden",
                height: 50,
                mt: 1,
                textAlign: "justify",
              }}
            >
              {title}
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{
                height: 50,
                textAlign: "justify",
              }}
            >
              {des}
            </Typography>
          </MainCard>
        </FadeInWhenVisible>
      )}
    </>
  );
};

UseCaseCard.propTypes = {
  id: PropTypes.number,
  color: PropTypes.string,
  name: PropTypes.string,
  image: PropTypes.string,
  description: PropTypes.string,
  offerPrice: PropTypes.number,
  salePrice: PropTypes.number,
  rating: PropTypes.number,
};

export default UseCaseCard;
