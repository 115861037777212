import { Alert, Box } from "@mui/material";
import { useTheme } from "@mui/system";
import React from "react";
interface PropsAlert {
  handleClose: () => void;
}
const AlertCookie = ({ handleClose }: PropsAlert) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        position: "fixed",

        left: 0,
        width: "100%",
        bottom: 0,
        display: "flex",
        alignItems: "center",
        zIndex: 999999,
      }}
    >
      <Alert
        onClose={handleClose}
        severity="info"
        sx={{
          width: "100%",

          //   color: "#ffffff",
          backgroundColor: "#01090E",
          color: "#ffffff",
          borderRadius: 0,
        }}
      >
        Chúng tôi sử dụng cookie để cải thiện trang web của chúng tôi và trải
        nghiệm của bạn. Bằng cách tiếp tục duyệt trang web của chúng tôi, bạn
        chấp nhận chính sách cookie của chúng tôi.
      </Alert>
    </Box>
  );
};

export default AlertCookie;
