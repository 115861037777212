
import { useMemo } from 'react';
// Import the memoized DataMenuItems
import NavMiniDrawer from './NavMiniDrawer';
import NavGroup from './NavGroup';
import { DataMenuItems } from '../../../../menu-items';


const MenuList = ({ drawerOpen }: { drawerOpen: boolean }) => {
  const items = DataMenuItems();

  const menuItems = useMemo(() => {
    return items.items.flatMap((item: any) => {
      if (drawerOpen || !item.children) {
        return <NavGroup key={item.id} item={item} />;
      } else {
        return item.children.flatMap((child: any) => {
          return <NavMiniDrawer key={child?.id} item={child} />;
        });
      }
    });
  }, [items]);

  return <>{menuItems}</>;
};

export { MenuList };
