import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { LazyLoadImage } from 'react-lazy-load-image-component';

// material-ui
import {
  CardContent,
  Grid,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";

// project import
import MainCard from "./MainCard";
import FadeInWhenVisible from "../Animation";

// ==============================|| PRODUCT CARD ||============================== //

const HighlightCard = ({
  title,
  icon,
  description,
}: {
  title: string;
  icon: any;
  description: string;
}) => {
  const [isLoading, setLoading] = useState(true);
  const theme = useTheme();
  useEffect(() => {
    setLoading(false);
  }, []);

  return (
    <>
      <MainCard
        content={false}
        width="100%"
        minHeight="300px"
        boxShadow
        sx={{
          backgroundColor: theme.palette.background.paper,
          padding: 2,
          "&:hover": {
            transform: "scale3d(1.02, 1.02, 1)",
            transition: "all .4s ease-in-out",
          },
        }}
      >
        <FadeInWhenVisible>
          <>
            <LazyLoadImage
              style={{
                height: '300px',
                width: '100%',
                textAlign: "center",
                alignItems: "center",
                backgroundSize: "cover"
              }}
              src={icon}
              alt="X-OR Cloud Server"
            />
            <CardContent sx={{ p: 3 }}>
              <Grid container spacing={2}>
                {title && description && (
                  <Grid item xs={12}>
                    <Typography
                      variant="h3"
                      color="primary"
                      sx={{
                        overflow: 'hidden',
                        height: 45,
                        textAlign: "center",
                      }}
                    >
                      {title}
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{
                        overflow: 'hidden',
                        minHeight: 130,
                        mt: 2,
                        textAlign: "justify",
                      }}
                    >
                      {description}
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </CardContent>
          </>
        </FadeInWhenVisible>
      </MainCard>
    </>
  );
};

HighlightCard.propTypes = {
  id: PropTypes.number,
  color: PropTypes.string,
  name: PropTypes.string,
  image: PropTypes.string,
  description: PropTypes.string,
  offerPrice: PropTypes.number,
  salePrice: PropTypes.number,
  rating: PropTypes.number,
};

export default HighlightCard;
