import React, { useContext, useEffect, useRef, useState } from "react";

// MUI
import {
  Box,
  ButtonBase,
  Avatar,
  Typography,
  Button,
  useMediaQuery,
  Stack,
  Popper,
  Chip,
  ClickAwayListener,
  Paper,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Divider,
  List,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import PerfectScrollbar from "react-perfect-scrollbar";
import Tooltip from '@mui/material/Tooltip';

// project import
import LogoSection from "../LogoSection";
import MobileSection from "./MobileSection";

//third party
import { IconMenu2, IconCategory } from "@tabler/icons";
import { Link, useNavigate } from "react-router-dom";

import MainCard from "../../../ui-component/card/MainCard";
import { IconLogout, IconSettings, IconUser } from "@tabler/icons";
import Transitions from "../../../ui-component/Transitions";
import useConfig from "../../../hooks/useConfig";
import Cookies from "js-cookie";

import avatar from "../../../assets/images/avatar.png";
import MegaMenuSection, { HeaderAvatarStyle } from "./MegaMenuSection";
import { AuthContext, AuthProvider } from "../../../keycloak/Auth";
import { useKeycloak } from "../../../hooks/useKeycloak";
import Login from "./Login";
import { decodeUserInfo } from "../../../keycloak/jwt";
const PORTAL_URL = process.env.REACT_APP_PORTAL_URL;

interface HeaderProps {
  handleLeftDrawerToggle: () => void;
}


function Header({ handleLeftDrawerToggle }: HeaderProps) {


  const theme: any = useTheme();
  const anchorRef = useRef<any>(null);
  const { borderRadius } = useConfig();
  const navigate = useNavigate();

  const matchMobile = useMediaQuery(theme.breakpoints.down("md"));

  const [open, setOpen] = useState(false);
  const [value, setValue] = useState("");
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const accessToken = Cookies.get("access_token");
  const user = decodeUserInfo(accessToken!);
  const { login, logout }: any = useKeycloak();


  const handleNavigatePricing = () => {
    navigate("/pricing");
  };


  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };


  const handleClose = (
    event: React.MouseEvent<HTMLDivElement> | MouseEvent | TouchEvent
  ) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };



  return (
    <>
      <Box
        sx={{
          ...theme.typography.commonAvatar,
          ...theme.typography.mediumAvatar,
          width: 228,
          display: "flex",
          [theme.breakpoints.down("md")]: {
            width: "auto",
          },
        }}
      >
        <Box
          component="span"
          sx={{ display: { xs: "none", md: "flex" }, flexGrow: 1, textAlign: 'center', alignItems: 'center' }}
        >
          <LogoSection />
        </Box>
        {matchMobile ? (
          <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
            <ButtonBase sx={{ borderRadius: "12px", overflow: "hidden" }}>
              <Avatar
                variant="rounded"
                sx={{
                  ...theme.typography.commonAvatar,
                  ...theme.typography.mediumAvatar,
                  overflow: "hidden",
                  transition: "all .2s ease-in-out",
                  background: theme.palette.dark.main,
                  color: theme.palette.secondary.main,
                  "&:hover": {
                    background: theme.palette.secondary.main,
                    color: theme.palette.secondary.light,
                  },
                }}
                onClick={handleLeftDrawerToggle}
                color="inherit"
              >
                <IconMenu2 stroke={1.5} size="20px" />
              </Avatar>
            </ButtonBase>
            <Box sx={{ ml: 1 }} onClick={() => navigate("/")}>
              <LogoSection />
            </Box>
          </Box>
        ) : (
          <ButtonBase sx={{ borderRadius: "12px", overflow: "hidden" }}>
            <Avatar
              variant="rounded"
              sx={{
                ...theme.typography.commonAvatar,
                ...theme.typography.mediumAvatar,
                overflow: "hidden",
                transition: "all .2s ease-in-out",
                background: theme.palette.dark.main,
                color: theme.palette.secondary.main,
                "&:hover": {
                  background: theme.palette.secondary.main,
                  color: theme.palette.secondary.light,
                },
              }}
              onClick={handleLeftDrawerToggle}
              color="inherit"
            >
              <IconMenu2 stroke={1.5} size="20px" />
            </Avatar>
          </ButtonBase>
        )}
      </Box>
      <Box sx={{ flexGrow: 1 }} />
      <Box sx={{ flexGrow: 1 }} />

      {/* mega menu section */}

      <Box sx={{ display: { xs: "none", sm: "block" } }}>
        <MegaMenuSection />
      </Box>
      {matchMobile ? (
        <>
          {accessToken ? (
            <>
              <Chip
                sx={{
                  height: "48px",
                  alignItems: "center",
                  borderRadius: "27px",
                  transition: "all .2s ease-in-out",
                  backgroundColor: theme.palette.background.paper,
                  borderColor: theme.palette.background.paper,
                  '&[aria-controls="menu-list-grow"], &:hover': {
                    borderColor: "rgba(42, 22, 111, 08)",
                    background: "rgba(42, 22, 111, 0.8) !important",
                    color: "#e3f2fd",
                    "& svg": {
                      stroke: theme.palette.primary.light,
                    },
                  },
                  "& .MuiChip-label": {
                    lineHeight: 0,
                  },
                }}
                icon={
                  <Avatar
                    src={avatar}
                    alt="user-images"
                    sx={{
                      ...theme.typography.caption,
                      margin: "8px 0 8px 8px !important",
                      width: 32, height: 32,
                      cursor: "pointer",
                    }}
                    ref={anchorRef}
                    aria-controls={open ? "menu-list-grow" : undefined}
                    aria-haspopup="true"
                    color="inherit"
                  />
                }
                label={
                  <IconSettings stroke={1.5} size="24px" color="#2196f3" />
                }
                variant="outlined"
                ref={anchorRef}
                aria-controls={open ? "menu-list-grow" : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
                color="default"
                aria-label="user-account"
              />

              <Popper
                placement="bottom"
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                modifiers={[
                  {
                    name: "offset",
                    options: {
                      offset: [0, 14],
                    },
                  },
                ]}
              >
                {({ TransitionProps }) => (
                  <ClickAwayListener onClickAway={handleClose}>
                    <Transitions in={open} {...TransitionProps}>
                      <Paper>
                        {open && (
                          <MainCard
                            sx={{
                              backgroundColor: theme.palette.background.paper,
                            }}
                            border={false}
                            elevation={16}
                            content={false}
                            boxShadow
                            shadow={theme.shadows[16]}
                          >

                            <PerfectScrollbar
                              style={{
                                height: "100%",
                                maxHeight: "calc(100vh - 250px)",
                                overflowX: "hidden",
                              }}
                            >

                              <Box sx={{ p: 2, pt: 0 }}>
                                <List
                                  component="nav"
                                  sx={{
                                    width: "100%",
                                    maxWidth: 350,
                                    minWidth: 300,
                                    backgroundColor:
                                      theme.palette.background.paper,
                                    borderRadius: "10px",
                                    [theme.breakpoints.down("md")]: {
                                      minWidth: "100%",
                                    },
                                    "& .MuiListItemButton-root": {
                                      mt: 0.5,
                                    },
                                  }}
                                >
                                  <Box sx={{ p: 2, pb: 0, mb: 2 }}>
                                    <Stack>
                                      <Stack
                                        direction="row"
                                        spacing={0.5}
                                        alignItems="center"
                                      >
                                        <Typography
                                          component="span"
                                          variant="h4"
                                          sx={{ fontWeight: 500, }}
                                        >
                                          Xin chào {user?.username}
                                        </Typography>
                                      </Stack>
                                    </Stack>

                                  </Box>
                                  <Divider />
                                  <ListItemButton
                                    sx={{ borderRadius: `${borderRadius}px` }}
                                    selected={selectedIndex === 4}
                                    component="a"
                                    href={`${PORTAL_URL}?token=${accessToken}`}
                                    target="_blank"
                                  >
                                    <ListItemIcon>
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="icon icon-tabler icon-tabler-layout-dashboard"
                                        width="20"
                                        height="20"
                                        viewBox="0 0 24 24"
                                        strokeWidth="1.5"
                                        stroke="#ffffff"
                                        fill="none"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      >
                                        <path
                                          stroke="none"
                                          d="M0 0h24v24H0z"
                                          fill="none"
                                        />
                                        <path d="M4 4h6v8h-6z" />
                                        <path d="M4 16h6v4h-6z" />
                                        <path d="M14 12h6v8h-6z" />
                                        <path d="M14 4h6v4h-6z" />
                                      </svg>
                                    </ListItemIcon>
                                    <ListItemText
                                      primary={
                                        <Typography variant="body2">
                                          Console Portal
                                        </Typography>
                                      }
                                    />
                                  </ListItemButton>

                                  <ListItemButton
                                    sx={{ borderRadius: `${borderRadius}px` }}
                                    selected={selectedIndex === 4}
                                    onClick={logout}
                                  >
                                    <ListItemIcon>
                                      <IconLogout
                                        stroke={1.5}
                                        size="22px"
                                        color="#ffffff"
                                      />
                                    </ListItemIcon>
                                    <ListItemText
                                      primary={
                                        <Typography variant="body2">
                                          Đăng xuất
                                        </Typography>
                                      }
                                    />
                                  </ListItemButton>
                                </List>
                              </Box>
                            </PerfectScrollbar>
                          </MainCard>
                        )}
                      </Paper>
                    </Transitions>
                  </ClickAwayListener>
                )}
              </Popper>
            </>
          ) : (
            <>
            </>
          )}
          <MobileSection />
        </>
      ) : (
        <>
          <Box sx={{ position: "relative", display: "inline-block" }}>
            <Box
              sx={{
                position: "absolute",
                bottom: "-2px",
                left: 0,
                width: "100%",
                height: "2.5px",
                backgroundColor: theme.palette.primary.main,
                transform: "scaleX(0)",
                transformOrigin: "left",
                transition: "transform 0.3s ease",
              }}
            />

          </Box>
          <Box marginRight={2} onClick={handleNavigatePricing}>
            <Box sx={{ position: "relative", display: "inline-block" }}>
              <Box
                sx={{
                  position: "absolute",
                  bottom: "-2px",
                  left: 0,
                  width: "100%",
                  height: "2.5px",
                  backgroundColor: theme.palette.primary.main,
                  transform: "scaleX(0)",
                  transformOrigin: "left",
                  transition: "transform 0.3s ease",
                }}
              />
              <Tooltip title={<span style={{ color: '#fff' }}>Bảng Giá</span>}>
                <Box
                  component="span"
                  sx={{
                    padding: 2,
                    borderBottom: "2px solid transparent",
                    transition: "border-color 0.3s ease",
                    display: "inline-block",
                    "&:hover": {
                      borderColor: theme.palette.primary.main,
                      cursor: "pointer",
                    },
                  }}
                >
                  <HeaderAvatarStyle
                    variant="rounded"
                    ref={anchorRef}
                    aria-controls={open ? "menu-list-grow" : undefined}
                    aria-haspopup="true"
                    // backgroundColor=""
                    onClick={handleToggle}
                    sx={{
                      display: { xs: "none", md: "flex" },
                      backgroundColor: theme.palette.background.paper,
                      borderRadius: 2,
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="icon icon-tabler icon-tabler-receipt-2"
                      width="20"
                      height="20"
                      viewBox="0 0 24 24"
                      strokeWidth="1.25"
                      stroke="rgb(30, 136, 229)"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <path d="M5 21v-16a2 2 0 0 1 2 -2h10a2 2 0 0 1 2 2v16l-3 -2l-2 2l-2 -2l-2 2l-2 -2l-3 2" />
                      <path d="M14 8h-2.5a1.5 1.5 0 0 0 0 3h1a1.5 1.5 0 0 1 0 3h-2.5m2 0v1.5m0 -9v1.5" />
                    </svg>
                  </HeaderAvatarStyle>
                </Box>
              </Tooltip>
              <Box
                marginRight={2}
                component="a"
                href="https://docs.x-or.cloud/"
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: "none" }}
              >
                <Tooltip title={<span style={{ color: '#fff' }}>Tài liệu hướng dẫn</span>}>
                  <Box
                    component="span"
                    sx={{
                      borderBottom: "2px solid transparent",
                      transition: "border-color 0.3s ease",
                      padding: 2,
                      display: "inline-block",
                      "&:hover": {
                        borderColor: theme.palette.primary.main,
                        cursor: "pointer",
                      },
                    }}
                  >
                    <HeaderAvatarStyle
                      variant="rounded"
                      ref={anchorRef}
                      aria-controls={open ? "menu-list-grow" : undefined}
                      aria-haspopup="true"
                      // backgroundColor=""
                      onClick={handleToggle}
                      sx={{
                        display: { xs: "none", md: "flex" },
                        backgroundColor: theme.palette.background.paper,
                        borderRadius: 2,
                      }}
                    >

                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="icon icon-tabler icon-tabler-book-2"
                        width="20"
                        height="20"
                        viewBox="0 0 24 24"
                        strokeWidth="1.25"
                        stroke="yellow"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <path d="M19 4v16h-12a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2h12z" />
                        <path d="M19 16h-12a2 2 0 0 0 -2 2" />
                        <path d="M9 8h6" />
                      </svg>
                    </HeaderAvatarStyle>
                    {/* <Typography
              variant="h4"
              sx={{
                cursor: "pointer",
              }}
              > 
              {" "}
              Document{" "}
            </Typography> */}
                  </Box>
                </Tooltip>
              </Box>
            </Box>
          </Box>
          <Login />
        </>
      )}
    </>
  );
}

export default Header;
