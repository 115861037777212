// material-ui
import { useTheme } from '@mui/material/styles';
import { Container, Grid, Typography, Stack, CardMedia } from '@mui/material';

// project imports
import SubCard from '../../../ui-component/card/SubCard';
import Avatar from '../../../ui-component/Avatar';

// assets
import FadeInWhenVisible from '../../../ui-component/Animation';
import { ProductDetailProps } from './HeaderSection';
import { BASE_URL } from '../../../api/config';
import { LazyLoadComponent } from 'react-lazy-load-image-component';



interface OfferCardProps {
    title: string;
    caption: string;
    image?: string;
}

export const OfferCard = ({ title, caption, image }: OfferCardProps) => {
    const theme = useTheme();
    const AvatarSx = { backgroundColor: 'transparent', color: theme.palette.secondary.main, width: 56, height: 56, filter: 'drop-shadow(rgb(130, 78, 220) 0px 0px 5px) drop-shadow(rgb(130, 78, 220) 0px 0px 10px)', opacity: 1 };
    return (
        <FadeInWhenVisible>
            <SubCard
                sx={{
                    bgcolor: 'dark.800',
                    background: theme.palette.background.paper,
                    borderColor: theme.palette.background.paper,
                    '&:hover': { boxShadow: 'none' },
                    minHeight: 250,
                }}
            >
                <Stack spacing={4}>
                    <LazyLoadComponent>
                        <Avatar variant="rounded" sx={AvatarSx}>
                            <CardMedia component="img" src={image} alt="Beautiful User Interface" />
                        </Avatar>
                    </LazyLoadComponent>
                    <Stack spacing={2}>
                        <Typography variant="h3" sx={{ fontWeight: 500 }} textAlign="start" color="primary">
                            {title}
                        </Typography>
                        <Typography variant="body2" sx={{ fontSize: '1rem' }} textAlign="justify">
                            {caption}
                        </Typography>
                    </Stack>
                </Stack>
            </SubCard>
        </FadeInWhenVisible>
    );
};

// =============================|| LANDING - FEATURE PAGE ||============================= //

const FeatureSection: React.FC<ProductDetailProps> = ({ productDetail }) => {
    const theme = useTheme();
    return (
        <>
            {productDetail?.attributes?.feature_section && (
                <Container sx={{ padding: 1, mt: 5, background: theme.palette.background.default }}>
                    <Grid container spacing={7.5} justifyContent="center">
                        <Grid item xs={12} md={7} sx={{ textAlign: 'center', mt: 2 }}>
                            <Grid container spacing={1.5}>
                                <Grid item xs={12}>
                                    <Typography variant="h2" sx={{ fontSize: { xs: '1.5rem', sm: '2.125rem' } }} textAlign="center">
                                        {productDetail?.attributes?.feature_section?.feature_title}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="body2" sx={{ fontSize: '1rem' }} textAlign="justify">
                                        {productDetail?.attributes?.feature_section?.feature_description}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={2} sx={{ '&> .MuiGrid-root > div': { height: '100%' } }}>
                                {productDetail?.attributes?.feature_section?.feature_card &&
                                    productDetail?.attributes?.feature_section?.feature_card.map((item: any) => {
                                        const image = `${BASE_URL}${item?.image?.data?.attributes?.url}`;
                                        return (
                                            <Grid item xs={12} md={4} sm={6} lg={4} key={item?.id}>
                                                <OfferCard title={item?.title} caption={item?.description} image={image} />
                                            </Grid>
                                        );
                                    })}
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            )}
        </>
    )
};

export default FeatureSection;
