// import { useEffect, useState } from 'react';
// import { Link } from 'react-router-dom';

// // material-ui
// import { useTheme } from '@mui/material/styles';
// import { Box, Card, Divider, Grid, Typography } from '@mui/material';
// import MuiBreadcrumbs from '@mui/material/Breadcrumbs';

// // project imports
// // import { BASE_PATH } from 'config';

// // assets
// import { IconTallymark1 } from '@tabler/icons';
// import AccountTreeTwoToneIcon from '@mui/icons-material/AccountTreeTwoTone';
// import HomeIcon from '@mui/icons-material/Home';
// import HomeTwoToneIcon from '@mui/icons-material/HomeTwoTone';
// import { gridSpacing } from '../store/constant';
// import { NavItemType, NavItemTypeObject, OverrideIcon } from '../types';
// import { BASE_PATH } from '../config';

// const linkSX = {
//     display: 'flex',
//     color: 'grey.900',
//     textDecoration: 'none',
//     alignContent: 'center',
//     alignItems: 'center'
// };

// interface BreadCrumbSxProps extends React.CSSProperties {
//     mb?: string;
//     bgcolor?: string;
// }

// interface BreadCrumbsProps {
//     items: any[];
//     card?: boolean;
//     divider?: boolean;
//     icon?: boolean;
//     icons?: boolean;
//     maxItems?: number;
//     navigation?: any[];
//     rightAlign?: boolean;
//     separator?: OverrideIcon;
//     title?: boolean;
//     titleBottom?: boolean;
//     sx?: BreadCrumbSxProps;
// }

// // ==============================|| BREADCRUMBS ||============================== //

// const Breadcrumbs = ({
//     card,
//     divider,
//     icon,
//     icons,
//     maxItems,
//     navigation,
//     rightAlign,
//     separator,
//     title,
//     titleBottom,
//     items,
//     ...others
// }: BreadCrumbsProps) => {
//     const theme = useTheme();

//     const iconStyle = {
//         marginRight: theme.spacing(0.75),
//         marginTop: `-${theme.spacing(0.25)}`,
//         width: '16px',
//         height: '16px',
//         color: theme.palette.secondary.main
//     };

//     const [main, setMain] = useState<NavItemType | undefined>();
//     const [item, setItem] = useState<any>();

//     console.log('item'. item)
//     console.log('main', main)
//     useEffect(() => {
//         navigation?.items?.map((menu: any, index: number) => {
//             if (menu.type && menu.type === 'group') {
//                 if (menu?.url && BASE_PATH + menu.url === document.location.pathname) {
//                     setMain(menu);
//                     setItem(menu);
//                 } else {
//                     getCollapse(menu as { children: NavItemType[]; type?: string });
//                 }
//             }
//             return false;
//         });
//     });

//     // set active item state
//     const getCollapse = (menu: NavItemTypeObject) => {
//         if (menu.children) {
//             menu.children.filter((collapse: NavItemType) => {
//                 if (collapse.type && collapse.type === 'collapse') {
//                     getCollapse(collapse as { children: NavItemType[]; type?: string });
//                 } else if (collapse.type && collapse.type === 'item') {
//                     if (document.location.pathname === BASE_PATH + collapse.url) {
//                         setMain(menu);
//                         setItem(collapse);
//                     }
//                 }
//                 return false;
//             });
//         }
//     };

//        // set active item state
//     // const getCollapse = (menu: NavItemTypeObject) => {
//     //     if (menu.children) {
//     //         menu.children.filter((group: NavItemType) => {
//     //             if (group.type && group.type === 'group') {
//     //                 getCollapse(group as { children: NavItemType[]; type?: string });
//     //             } else if (group.type && group.type === 'item') {
//     //                 if (document.location.pathname === BASE_PATH + group.url) {
//     //                     setMain(menu);
//     //                     setItem(group);
//     //                 }
//     //             }
//     //             return false;
//     //         });
//     //     }
//     // };

//     // item separator
//     const SeparatorIcon = separator!;
//     const separatorIcon = separator ? <SeparatorIcon stroke={1.5} size="16px" /> : <IconTallymark1 stroke={1.5} size="16px" />;

//     let mainContent;
//     let itemContent;
//     let breadcrumbContent: React.ReactElement = <Typography />;
//     let itemTitle: NavItemType['title'] = '';
//     let CollapseIcon;
//     let ItemIcon;

//     // collapse item
//     if (main && main.type === 'collapse') {
//         CollapseIcon = main.icon ? main.icon : AccountTreeTwoToneIcon;
//         mainContent = (
//             <Typography component={Link} to="#" variant="subtitle1" sx={linkSX}>
//                 {icons && <CollapseIcon style={iconStyle} />}
//                 {main.title}
//             </Typography>
//         );
//     }

//     // items
//     if ((item && item.type === 'item') || (item?.type === 'group' && item?.url)) {
//         itemTitle = item.title;

//         ItemIcon = item.icon ? item.icon : AccountTreeTwoToneIcon;
//         itemContent = (
//             <Typography
//                 variant="subtitle1"
//                 sx={{
//                     display: 'flex',
//                     textDecoration: 'none',
//                     alignContent: 'center',
//                     alignItems: 'center',
//                     color: 'grey.500'
//                 }}
//             >
//                 {icons && <ItemIcon style={iconStyle} />}
//                 {itemTitle}
//             </Typography>
//         );

//         // main
//         if (item.breadcrumbs !== false) {
//             breadcrumbContent = (
//                 <Card
//                     sx={{
//                         marginBottom: card === false ? 0 : theme.spacing(gridSpacing),
//                         // border: card === false ? 'none' : '1px solid',
//                         // borderColor: theme.palette.mode === 'dark' ? theme.palette.background.default : theme.palette.primary[200] + 75,
//                         background: card === false ? 'transparent' : theme.palette.background.default
//                     }}
//                     {...others}
//                 >
//                     <Box sx={{ p: 2, pl: card === false ? 0 : 2 }}>
//                         <Grid
//                             container
//                             direction={rightAlign ? 'row' : 'column'}
//                             justifyContent={rightAlign ? 'space-between' : 'flex-start'}
//                             alignItems={rightAlign ? 'center' : 'flex-start'}
//                             spacing={1}
//                         >
//                             {title && !titleBottom && (
//                                 <Grid item>
//                                     <Typography variant="h3" sx={{ fontWeight: 500 }}>
//                                         {item.title}
//                                     </Typography>
//                                 </Grid>
//                             )}
//                             <Grid item>
//                                 <MuiBreadcrumbs
//                                     sx={{ '& .MuiBreadcrumbs-separator': { width: 16, ml: 1.25, mr: 1.25 } }}
//                                     aria-label="breadcrumb"
//                                     maxItems={maxItems || 8}
//                                     separator={separatorIcon}
//                                 >
//                                     <Typography component={Link} to="/" color="inherit" variant="subtitle1" sx={linkSX} aria-label="home">
//                                         {icons && <HomeTwoToneIcon sx={iconStyle} />}
//                                         {icon && <HomeIcon sx={{ ...iconStyle, mr: 0 }} />}
//                                         {!icon && 'Dashboard'}
//                                     </Typography>
//                                     {mainContent}
//                                     {itemContent}
//                                 </MuiBreadcrumbs>
//                             </Grid>
//                             {title && titleBottom && (
//                                 <Grid item>
//                                     <Typography variant="h3" sx={{ fontWeight: 500 }}>
//                                         {item.title}
//                                     </Typography>
//                                 </Grid>
//                             )}
//                         </Grid>
//                     </Box>
//                     {card === false && divider !== false && <Divider sx={{ borderColor: theme.palette.primary.main, mb: gridSpacing }} />}
//                 </Card>
//             );
//         }
//     }

//     return breadcrumbContent;
// };

// export default Breadcrumbs;


import React, { useEffect, useState } from 'react';
import Link from '@mui/material/Link';
import HomeIcon from '@mui/icons-material/Home';
import { Breadcrumbs } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

interface BreadcrumbsProps {
  categoryName: string;
  productName: string; // Assuming this is the array of items in your sidebar
}

const BreadCrumbs: React.FC<BreadcrumbsProps> = ({ categoryName, productName }) => {

  // You can replace this with your custom separator icon

  return (
    <Breadcrumbs aria-label="breadcrumb" separator={<NavigateNextIcon fontSize="small" />}>
      <Link
        underline="hover"
        sx={{ display: 'flex', alignItems: 'center' }}
        color="primary"
        href="/"
      >
        <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
        Home
      </Link>
      {categoryName && (
        <Link
          underline="none"
          sx={{ display: 'flex', alignItems: 'center' }}
          color="primary"
        >
          {categoryName}
        </Link>
      )}
      {productName && (
        <Link
          underline="none"
          sx={{ display: 'flex', alignItems: 'center' }}
          color="primary"
        >
          {productName}
        </Link>
      )}
    </Breadcrumbs>
  );
};

export default BreadCrumbs;

