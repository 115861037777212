import React from "react";
import { Typography, Box, Divider, Grid, Stack } from "@mui/material";
import PriceCard from "../pricecard/PriceCard";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useTheme } from "@mui/system";
import MobileCard from "../pricecard/MobileCard";

const ProductPrice = (product: any) => {
  const theme = useTheme();

  const tableCellStyled = {
    fontWeight: "bolder",
    cursor: "pointer",
    borderBottom: `1px solid rgb(189, 200, 240, 0.2)`,
  };

  return (
    <>
      {product?.product?.attributes?.package?.data[0]?.attributes?.attributes
        ?.length > 3 &&
        !product.product.attributes.package.data[0].attributes.packageCode.startsWith(
          "OSS"
        ) ? (
        <>
          <TableContainer
            sx={{ backgroundColor: theme.palette.background.default }}
          >
            <Table sx={{ minWidth: 650 }}>
              <TableHead>
                <TableRow>
                  <TableCell align="center" style={tableCellStyled}>
                    vCPUs
                  </TableCell>
                  <TableCell align="center" style={tableCellStyled}>
                    Memory
                  </TableCell>
                  <TableCell align="center" style={tableCellStyled}>
                    Storage
                  </TableCell>
                  <TableCell align="center" style={tableCellStyled}>
                    Bandwidth
                  </TableCell>
                  <TableCell align="center" style={tableCellStyled}>
                    Giá/tháng
                  </TableCell>
                  <TableCell style={tableCellStyled}> </TableCell>
                </TableRow>
              </TableHead>
              <PriceCard
                pack={product?.product?.attributes?.package?.data}
                productName={product?.product?.attributes?.name}
              />
            </Table>
          </TableContainer>
        </>
      ) : (
        <>
          <Stack
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              flexWrap: "wrap",
            }}
          >
            <MobileCard
              pack={product?.product}
              productName={product?.product?.attributes?.name}
            />
          </Stack>
        </>
      )}
    </>
  );
};

export default ProductPrice;
