import React, { useEffect, useState } from "react";
import { useLocation, Link as RouterLink } from "react-router-dom";

// material-ui
import { useTheme } from "@mui/material/styles";
import {
  Button,
  Card,
  CardContent,
  Container,
  FormControl,
  Grid,
  InputLabel,
  OutlinedInput,
  TextField,
  Typography,
  Link,
  Alert,
  FormControlLabel,
  Checkbox,
  FormHelperText,
  Box
} from "@mui/material";

// third-party
import { PatternFormat } from "react-number-format";

// project imports
import { gridSpacing } from "../../store/constant";

// assets
import mailImg from "../../assets/images/mail.svg";
import { useDispatch } from "../../store";

type FormUserData = {
  fullname: string;
  companyName: string;
  email: string;
  phoneNumber: string;
  note: string;
  product: string;
  source: string;
  campaign: string;
};

// ===========================|| CONTACT CARD - FORMS ||=========================== //

const ContactSale = () => {
  const theme = useTheme();
  const location = useLocation();
  const dispatch = useDispatch();
  const [userMessage, setUserMessage] = useState("");
  const { packageDetail, productName } = location.state || {};
  const [isLoading, setIsLoading] = useState(false);
  const [formUserData, setFormUserData] = useState<FormUserData>({
    fullname: "",
    companyName: "",
    email: "",
    phoneNumber: "",
    note: "",
    product: userMessage,
    source: window.location.origin || "",
    campaign: "",
  });
  const [error, setError] = useState(false);
  const [formSuccess, setFormSuccess] = useState(false);
  const [checked, setChecked] = useState(false);
  const [nameError, setNameError] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState("");
  const [emailError, setEmailError] = useState("");

  const handleChange = (name: keyof FormUserData, value: string) => {
    if (name === "phoneNumber") {
      if (!value.startsWith("0")) {
        setPhoneNumberError(
          "Số điện thoại không hợp lệ. Vui lòng nhập số điện thoại di động của bạn ở dạng 0XXXXXXXXX"
        );
        return;
      } else {
        setPhoneNumberError("");
      }
    }

    setError(false);
    setFormSuccess(false);
    setFormUserData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSendForm = () => {
    setIsLoading(true);
    if (!formUserData.fullname) {
      setNameError("Họ và Tên là trường bắt buộc");
      setIsLoading(false);
      return;
    }
    if (!formUserData.phoneNumber) {
      setPhoneNumberError("Số điện thoại là trường bắt buộc");
      setIsLoading(false);
      return;
    }

    const phone = formUserData.phoneNumber.trim();
    if (!/^\d+$/.test(phone) || phone.length !== 10) {
      setPhoneNumberError(
        "Số điện thoại không hợp lệ. Vui lòng nhập số điện thoại di động của bạn ở dạng 0XXXXXXXXX"
      );
      setIsLoading(false);
      return;
    }

    if (!formUserData.email) {
      setEmailError("Email là trường bắt buộc");
      setIsLoading(false);
      return;
    }
    setNameError("");
    setPhoneNumberError("");
    setEmailError("");
    const data = { ...formUserData };

    const formApi = process.env.REACT_APP_ODOO_URL || "";

    const xhr = new XMLHttpRequest();
    xhr.open("POST", formApi, true);
    xhr.setRequestHeader("Content-Type", "application/json");
    xhr.onload = function () {
      if (xhr.status === 200) {
        const responseData = JSON.parse(xhr.responseText);
        if (responseData.statusCode === 200) {
          setIsLoading(false);
          setFormUserData({
            fullname: "",
            companyName: "",
            email: "",
            phoneNumber: "",
            note: "",
            product: "",
            source: window.location.origin || "",
            campaign: "",
          });
          setFormSuccess(true);
          setError(false);
          setChecked(false);
        }
      } else {
        setIsLoading(false);
        setFormSuccess(false);
        if (xhr.status === 409) {
          setError(true);
          setChecked(false);
        } else {
          setError(true);
          setChecked(false);
        }
      }
    };

    xhr.onerror = function () {
      setIsLoading(false);
      setFormSuccess(false);
      setError(true);
      setChecked(false);
    };

    xhr.send(JSON.stringify(data));
  };

  useEffect(() => {
    const currentUrl = window.location.href;
    const pathname = new URL(currentUrl).pathname;
    const decodedPathname = decodeURIComponent(pathname.replace(/ /g, "_"));
    setFormUserData((prevData) => ({
      ...prevData,
      product: decodedPathname,
    }));
    if (typeof packageDetail === "object" && productName) {
      setFormUserData((prevData) => ({
        ...prevData,
        product: `${productName} - Package: ${packageDetail.price
          }VND - ${packageDetail.attributes
            .map((unit: any) => `${unit.title}: ${unit.value} ${unit.unit}`)
            .join(", ")}`,
      }));
    } else if (typeof packageDetail === "string" && productName) {
      setFormUserData((prevData) => ({
        ...prevData,
        product: `${packageDetail} - ${productName}`,
      }));
    }
  }, []);

  return (
    <Container>
      <Grid container justifyContent="center" spacing={gridSpacing}>
        <Grid
          item
          sm={10}
          md={7}
          // sx={{ mt: 5 }}
          sx={{ mt: { md: 10, xs: 5 }, mb: 2 }}
        >
          <Grid container spacing={gridSpacing}>
            <Grid item xs={12}>
              <Typography variant="h1" >
                Liên hệ với X-OR Cloud
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="h4"
                component="div"
                textAlign="justify"
                sx={{
                  fontWeight: 400,
                  lineHeight: 1.4,
                }}
                color="white"
              >
                X-OR CLOUD xin cảm ơn sự quan tâm của quý khách đến dịch vụ của chúng tôi. Để có thể phục vụ quý khách được tốt nhất, đội ngũ chuyên gia của chúng tôi mong sớm nhận được thông tin từ quý khách. Xin trân trọng cảm ơn!
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={10}>
          <form onSubmit={handleSendForm}>
            <Card elevation={4}>
              <CardContent sx={{ p: 4 }}>
                <Grid container spacing={gridSpacing}>
                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth required>
                      <InputLabel>Họ và Tên</InputLabel>
                      <OutlinedInput
                        type="text"
                        label="Họ và Tên"
                        name="fullname"
                        placeholder="Họ và Tên"
                        value={formUserData.fullname}
                        onChange={(e: any) =>
                          handleChange("fullname", e.target.value)
                        }
                      />
                      {nameError && (
                        <FormHelperText error>{nameError}</FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                      <InputLabel>Tên Công Ty</InputLabel>
                      <OutlinedInput
                        type="text"
                        name="companyName"
                        label="Company Name"
                        placeholder="Tên Công Ty"
                        value={formUserData.companyName}
                        onChange={(e: any) =>
                          handleChange("companyName", e.target.value)
                        }
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth required>
                      <InputLabel>Email</InputLabel>
                      <OutlinedInput
                        type="text"
                        label="Email Address"
                        name="email"
                        placeholder="Email"
                        value={formUserData.email}
                        onChange={(e: any) =>
                          handleChange("email", e.target.value)
                        }
                      />
                      {emailError && (
                        <FormHelperText error>{emailError}</FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth required>
                      <InputLabel>Số điện thoại</InputLabel>
                      <PatternFormat
                        format="##########"
                        placeholder="Số điện thoại"
                        label="Số điện thoại"
                        customInput={OutlinedInput}
                        value={formUserData.phoneNumber}
                        onChange={(e: any) =>
                          handleChange("phoneNumber", e.target.value)
                        }
                      />
                      {phoneNumberError && (
                        <FormHelperText error>
                          {phoneNumberError}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <TextField
                        id="outlined-multiline-static1"
                        placeholder="Tin nhắn"
                        multiline
                        fullWidth
                        rows={4}
                        name="note"
                        value={formUserData.note}
                        onChange={(e: any) =>
                          handleChange("note", e.target.value)
                        }
                      />
                    </FormControl>
                    {/* {packageDetail && (
                      <Grid item xs={12} mt={2}>
                        <FormControl fullWidth>
                          <TextField
                            id="outlined-multiline-static1"
                            placeholder="Package"
                            multiline
                            fullWidth
                            rows={4}
                            defaultValue=""
                            name="package"
                            value={userMessage}
                          />
                        </FormControl>
                      </Grid>
                    )} */}
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={gridSpacing}>
                      <Grid item>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={checked}
                              onChange={(e) => setChecked(e.target.checked)}
                              color="primary"
                            />
                          }
                          label={
                            <Typography variant="body2" component="div">
                              Tôi đồng ý với
                              <Typography
                                variant="subtitle1"
                                component={RouterLink}
                                target="_blank"
                                to="https://docs.x-or.cloud/x-or-cloud/dieu-khoan-su-dung-dich-vu"
                                color="primary"
                                sx={{ mx: 0.5 }}
                              >
                                Điền khoản chính sách và dịch vụ
                              </Typography>
                            </Typography>
                          }
                        />
                      </Grid>
                    </Grid>
                    {error && (
                      <Grid item xs={12}>
                        <Alert
                          severity="error"
                          variant="outlined"
                          sx={{ color: "#c62828", alignItems: "center" }}
                        >
                          {
                            <p>
                              Có lỗi trong quá trình tiếp nhận yêu cầu, vui lòng
                              thử lại hoặc liên hệ chúng tôi qua email{" "}
                              <Link href="mailto:sales@x-or.cloud">
                                sales@x-or.cloud
                              </Link>{" "}
                            </p>
                          }
                        </Alert>
                      </Grid>
                    )}
                    {formSuccess && (
                      <Grid item xs={12}>
                        <Alert
                          severity="success"
                          variant="outlined"
                          sx={{ color: "#00c853" }}
                        >
                          Gửi thành công
                        </Alert>
                      </Grid>
                    )}
                    <Grid item textAlign="start" sx={{ mt: 1 }}>
                      <Button
                        variant="contained"
                        onClick={handleSendForm}
                        disabled={checked === false || isLoading === true}
                        style={{
                          backgroundColor: isLoading
                            ? "grey"
                            : checked
                              ? "#f3ba0d"
                              : "grey",
                          color: isLoading
                            ? "black"
                            : checked
                              ? "#fff"
                              : "black",
                        }}
                      >
                        Gửi
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </form>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ContactSale;
