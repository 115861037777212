import { Typography } from '@mui/material';
import { Stack } from '@mui/system';
import React, { useEffect, useState } from 'react';

type MetricCardProps = {
    value: number;
    type: string;
    caption: string;
    unit: string;
}
const MetricCard: React.FC<MetricCardProps> = ({ value, type, caption, unit }) => {
    const [count, setCount] = useState(Math.round(value * 4 / 5));

    useEffect(() => {
        if (type === 'dynamic') {
            const interval = setInterval(() => {
                setCount(prevCount => {
                    if (prevCount < value) {
                        return prevCount + 111
                    } else {
                        clearInterval(interval);
                        return value;
                    }
                });
            });
            return () => clearInterval(interval);
        }
    }, [type, value]);




    return (
        <Stack direction="column">
            <Typography
                variant="h3"
                textAlign="start"
                sx={{
                    fontSize: "1.5rem",
                    fontWeight: 200,
                    lineHeight: 1.5,
                    filter:
                        "drop-shadow(rgb(130, 78, 220) 0px 0px 5px) drop-shadow(rgb(130, 78, 220) 0px 0px 10px)",
                    opacity: 1,
                }}
            >
                {type === "dynamic" ? count.toLocaleString() : value.toLocaleString()} {unit}
            </Typography>
            <Typography variant="subtitle1" textAlign="start">
                {caption}
            </Typography>
        </Stack>
    );
};

export default MetricCard;