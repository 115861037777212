import { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

// material-ui
import { styled, useTheme } from "@mui/material/styles";
import {
	Avatar,
	ClickAwayListener,
	Grid,
	List,
	ListItemButton,
	ListItemIcon,
	ListSubheader,
	ListItemText,
	Paper,
	Popper,
	Typography,
	useMediaQuery,
	Box,
	Tooltip,
} from "@mui/material";

// third-party
import PerfectScrollbar from "react-perfect-scrollbar";

// project imports
import MainCard from "../../../../ui-component/card/MainCard";
import Transitions from "../../../../ui-component/Transitions";

// assets
import Banner from "./Banner";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { IconAccessPoint } from "@tabler/icons";
import { drawerWidth, gridSpacing } from "../../../../store/constant";
import { DataMenuItems } from "../../../../menu-items";
import { dispatch } from "../../../../store";
import { getSingleProduct } from "../../../../store/slices/product";

export const HeaderAvatarStyle = styled(Avatar)(({ theme }) => ({
	// ...theme.typography
	// ...theme.typography.mediumAvatar,
	// background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.secondary.light,
	background: theme.palette.background.default,
	color: theme.palette.secondary.main,
	// color: theme.palette.mode === 'dark' ? theme.palette.secondary.main : theme.palette.secondary.dark,
	"&:hover": {
		background: theme.palette.mode === "dark" ? theme.palette.secondary.main : theme.palette.secondary.dark,
		color: theme.palette.mode === "dark" ? theme.palette.secondary.light : theme.palette.secondary.light,
	},
}));

// ==============================|| SEARCH INPUT - MEGA MENu||============================== //

const MegaMenuSection = () => {
	const theme = useTheme();
	const matchDownMd = useMediaQuery(theme.breakpoints.down("md"));
	const navigate = useNavigate();

	const data = DataMenuItems();

	console.log("data", data);

	const [open, setOpen] = useState(false);
	const anchorRef = useRef<any>(null);

	const handleToggle = () => {
		setOpen((prevOpen) => !prevOpen);
	};

	const handleClose = (event: React.MouseEvent<HTMLDivElement> | MouseEvent | TouchEvent) => {
		if (anchorRef.current && anchorRef.current.contains(event.target)) {
			return;
		}

		setOpen(false);
	};

	const itemHandler = async (child: any) => {
		dispatch(getSingleProduct(child?.id));

		navigate(child?.url.toLowerCase());
	};

	useEffect(() => {
		matchDownMd && open && setOpen(false);
		// eslint-disable-next-line
	}, [matchDownMd]);

	return (
		<Tooltip title={<span style={{ color: "#fff" }}>Sản phẩm</span>}>
			<Box
				component="span"
				sx={{
					padding: 2,
					borderBottom: "2px solid transparent",
					transition: "border-color 0.3s ease",
					display: "inline-block",
					"&:hover": {
						borderColor: theme.palette.primary.main,
						cursor: "pointer",
					},
				}}
			>
				<HeaderAvatarStyle
					variant="rounded"
					ref={anchorRef}
					aria-controls={open ? "menu-list-grow" : undefined}
					aria-haspopup="true"
					onClick={handleToggle}
					sx={{
						display: { xs: "none", md: "flex" },
						backgroundColor: theme.palette.background.paper,
						borderRadius: 2,
					}}
				>
					<IconAccessPoint stroke={1.5} size="20px" color="rgb(124, 77, 255)" />
				</HeaderAvatarStyle>
				<Popper
					placement="bottom-end"
					open={open}
					anchorEl={anchorRef.current}
					role={undefined}
					transition
					disablePortal
					modifiers={[
						{
							name: "offset",
							options: {
								offset: [150, 20],
							},
						},
					]}
				>
					{({ TransitionProps }) => (
						<ClickAwayListener onClickAway={handleClose}>
							<Transitions in={open} {...TransitionProps}>
								<PerfectScrollbar>
									<Paper
										sx={{
											width: {
												md: `calc(100vw - 100px)`,
												lg: `calc(100vw - ${drawerWidth + 100}px)`,
												xl: `calc(100vw - ${drawerWidth + 140}px)`,
											},
											maxHeight: "60vh",
											maxWidth: { xl: 900, md: 1000 },
										}}
									>
										{open && (
											<MainCard
												border={false}
												elevation={16}
												content={false}
												boxShadow
												shadow={theme.shadows[16]}
												sx={{
													overflow: {
														p: 1,
														xs: "visible",
														md: "hidden",
														backgroundColor: theme.palette.background.default,
													},
												}}
											>
												<Grid container alignItems="flex-start">
													<Grid item md={4}>
														<Banner />
													</Grid>

													<Grid item md={8}>
														<Grid
															container
															spacing={1}
															sx={{
																pt: 3,
																textAlign: "start",
																"& .MuiListItemButton-root:hover": {
																	background: "transparent",
																	"& .MuiTypography-root": {
																		color: "secondary.main",
																	},
																},
																"& .MuiListItemIcon-root": {
																	minWidth: 10,
																},
															}}
														>
															{data?.items?.map((item, index) => {
																return (
																	<Grid
																		item
																		xs={4}
																		sx={{
																			display: "flex",
																			flexDirection: "column",
																		}}
																		key={index}
																	>
																		<List
																			component="nav"
																			aria-labelledby="nested-list-user"
																			sx={{ padding: 1, mb: 2 }}
																			key={item?.id}
																			subheader={
																				<ListSubheader id="nested-list-user" sx={{ padding: 1 }}>
																					<Typography variant="h4" color="yellow">
																						{item?.title}
																					</Typography>
																				</ListSubheader>
																			}
																		>
																			{item?.children?.map((child: any) => {
																				return (
																					<ListItemButton
																						disableRipple
																						// component={Link}
																						onClick={() => itemHandler(child)}
																						// to={child?.url}
																					>
																						<ListItemIcon>
																							<FiberManualRecordIcon sx={{ fontSize: "0.5rem" }} />
																						</ListItemIcon>
																						<ListItemText primary={child?.title} />
																					</ListItemButton>
																				);
																			})}
																		</List>
																	</Grid>
																);
															})}
														</Grid>
													</Grid>
												</Grid>
											</MainCard>
										)}
									</Paper>
								</PerfectScrollbar>
							</Transitions>
						</ClickAwayListener>
					)}
				</Popper>
			</Box>
		</Tooltip>
	);
};

export default MegaMenuSection;
