import * as React from "react";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

export interface Row {
  attributes: {
    id: string;
    attributes?: {
      value: string;
      unit: string;
    }[];
    price: string;
  };
}

export default function PriceCard(pack: any, productName: string) {

  const navigate = useNavigate()

  const handleOrderNowClick = (row: Row) => {
    navigate('/contact', {
      state: {
        packageDetail: row?.attributes,
        productName: pack?.productName
      },
    })
  }

  const rows = pack?.pack?.map((packData: any) => {
    return packData;
  });


  return (
    <>
      <TableBody>
        {rows?.map((row: any, i: number) => {
          return (
            <TableRow
              key={i}
            >
              {row.attributes?.attributes?.map((des: any, i: number) => {
                return (
                  <TableCell align="center" sx={{ borderBottom: '1px solid rgb(189, 200, 240, 0.2)', zIndex: 999 }} key={i}>
                    {des?.value} {des?.unit}
                  </TableCell>
                )
              })}

              <TableCell align="center" sx={{ borderBottom: '1px solid rgb(189, 200, 240, 0.2)', zIndex: 999, fontWeight: 'bolder' }}>{row?.attributes?.price.toLocaleString()} VND</TableCell>
              <TableCell align="right" sx={{ borderBottom: '1px solid rgb(189, 200, 240, 0.2)', zIndex: 999 }}>
                <Button variant="contained" onClick={() => handleOrderNowClick(row)}>
                  {row?.attributes?.packageCode.startsWith('OSS') ? "Mua Ngay" : "Liên Hệ"}
                </Button>
              </TableCell>
            </TableRow>
          )
        })}
      </TableBody>
    </>
  );
}
