import axios from 'axios';
import { BASE_URL, domain } from './config';


const instance = axios.create({
    baseURL: BASE_URL,
});


const categoryApi = {
    getAll: () => {
        return instance.get(`/api/categories?filters[domain_services][type][$eq]=${domain}&populate=icon&populate=products&populate=products.packages`);
    },
    getById: (id: number | string) => {
        return instance.get(`/api/categories/${id}?filters[domain_services][type][$eq]=${domain}&populate=products&populate=products.packages&populate=products.packages.attributes&populate=products.icon`);
    },
    getAllById: (id: number) => {
        return instance.get(`/api/categories/${id}?filters[domain_services][type][$eq]=${domain}&populate=*`)
    }
}


export default categoryApi;