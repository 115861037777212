import React from "react";
import PropTypes from "prop-types";

// material-ui
import { useTheme } from "@mui/material/styles";
import { Grid, Box, Typography, Button, Avatar } from "@mui/material";

// third party

import { Props } from "react-apexcharts";
import Chart from "react-apexcharts";

// project imports
import MainCard from "../card/MainCard";

// assets

import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useEffect } from "react";




// ===========================|| MARKET SHARE CHART CARD ||=========================== //

const CompareChartCard: React.FC<any> = ({ product, categoryId }) => {
  const navigate = useNavigate();
  const theme: any = useTheme();;

  const priceData = useSelector((state: any) => state.product.product);

  const [chartData, setChartData] = React.useState<any>(null);

  const data = priceData?.attributes?.compare_prices?.map((item: any) => {

    return {
      name: item?.name,
      data: JSON.parse(item?.data),
      color: item?.color
    };
  });

  useEffect(() => {
    const chart: Props = {
      height: 300,
      type: "area",
      options: {
        chart: {
          id: "market-sale-chart",
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
          sparkline: {
            enabled: true,
          },
        },
        dataLabels: {
          enabled: false,
          style: {
            colors: ["#000"]
          }
        },
        stroke: {
          curve: "smooth",
          width: 2,
        },
        fill: {
          type: "gradient",
          gradient: {
            shadeIntensity: 1,
            opacityFrom: 0.5,
            opacityTo: 0,
            stops: [0, 80, 100],
          },
        },
        legend: {
          show: false,
        },
        yaxis: {
          min: 1,
          max: 150,
          labels: {
            show: false,
          },
        },
      },
      tooltip: {
        enabled: true,
        shared: true,
        followCursor: true,
        intersect: false,
        y: {
          formatter: function (value: any, { series, seriesIndex, dataPointIndex, w }: any) {
            return `${w.globals.labels[dataPointIndex]}: $${value}`;
          },
        },
      },
      series: data
        ? data?.map((item: any) => ({
          name: item?.name,
          data: item?.data?.map((value: number, dataIndex: number) => {
            const monthNames = [
              'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
              'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
            ];

            const currentDate = new Date();
            const currentYear = currentDate.getFullYear();

            return {
              x: `${monthNames[dataIndex]} ${currentYear}`,
              y: value,
            };
          }),
          color: item?.color,
        }))
        : [],
    };

    setChartData(chart);
  }, [priceData]);


  return (
    <>
      {chartData && (
        <MainCard sx={{ "&>div": { p: 0, pb: "0px !important" } }}>
          <Box sx={{ p: 3 }}>
            <Grid container direction="column" spacing={3}>
              <Grid item container spacing={1} alignItems="center">
                <Grid item >
                  <Typography variant="h3">Bảng so sánh giá thị trường</Typography>
                </Grid>
                <Grid item xs zeroMinWidth />
                <Grid item>
                  <Button
                    variant="outlined"
                    onClick={() => navigate(`/pricing?category=${categoryId}&product=${product.id}`)}
                  >
                    Bảng Giá
                  </Button>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  sx={{ mt: -2.5, fontWeight: 400 }}
                  color="inherit"
                  variant="h5"
                >
                  {priceData?.attributes?.sub_chart}
                </Typography>
              </Grid>
              <Grid item container alignItems="center" spacing={3}>
                {data && data?.map((value: { name: any, color: any }, index: number) => (
                  <Grid item key={index}>
                    <Grid container alignItems="center" spacing={1}>
                      <Grid
                        item
                        justifyContent="center"
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <div
                          style={{
                            width: "20px",
                            height: "20px",
                            borderRadius: "50%",
                            backgroundColor: `${value?.color}`,
                            display: "inline-block",
                            padding: 2,
                            marginRight: 1
                          }}
                        ></div>
                        <Typography variant="h5" sx={{ marginLeft: 2 }}>{value?.name}</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Box>
          {chartData && (
            <Grid item sx={{ padding: 2 }}>
              <Chart {...chartData} style={{ color: 'black' }} />
            </Grid>
          )}
        </MainCard>
      )}
    </>
  );
};

CompareChartCard.propTypes = {
  chartchartData: PropTypes.object,
};

export default CompareChartCard;
