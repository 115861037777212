import axios from "axios";
import { BASE_URL, domain } from "./config";

const instance = axios.create({
  baseURL: BASE_URL,
});

const productApi = {
  getSingleProductById: (id: number) => {
    return instance.get(
      `/api/products/${id}?populate=usecases&populate=usecases.icon&populate=package&populate=package.attributes&populate=highlight.icon&populate=use_cases.icon&populate=url&populate=compare_prices&populate=video&populate=Section&populate=Section.image&populate=Section.content&populate=blog_image_header&populate=feature_section&populate=feature_section.feature_card&populate=feature_section.feature_card.image&populate=section_img_animation&populate=section_img_animations.images.image&populate=section_system_metrics&populate=section_system_metrics.metrics&populate=section_system_metrics.features&populate=section_system_metrics.features.icon`
    );
  },
  getRelatedProduct: () => {
    return instance.get(
      `api/product-relateds?filters[domain_services][type][$eq]=${domain}&populate=image`
    )
  }
};

export default productApi;
