
import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { dispatch } from "../../../../../store";

// material-ui
import { useTheme } from "@mui/material/styles";
import {
  ListItemButton,
  ListItemIcon,
  Popover,
  Typography,
  useMediaQuery,
} from "@mui/material";

// project imports
import { MENU_OPEN, SET_MENU } from "../../../../../store/action";

// assets
import { IRootState } from "../../../../../store/reducer";

import { getSingleProduct } from "../../../../../store/slices/product";

// ==============================|| SIDEBAR MENU LIST ITEMS ||============================== //
interface props {
  item: any;
}

const NavMiniDrawer: React.FC<props> = ({ item }) => {
  const theme: any = useTheme();
  const { pathname } = useLocation();
  const customization = useSelector((state: IRootState) => state.customization);
  const matchesSM = useMediaQuery(theme.breakpoints.down("lg"));
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handleMouseEnter = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const open = Boolean(anchorEl);

  const handleMouseLeave = () => {
    setAnchorEl(null);
  };

  const itemHandler = async (id: number) => {
    dispatch({ type: MENU_OPEN, id });
    if (matchesSM) dispatch({ type: SET_MENU, opened: false });
    dispatch(getSingleProduct(id));

    navigate(item?.url.toLowerCase());
  };

  // active menu item on page load
  useEffect(() => {
    const currentIndex = document.location.pathname
      .toString()
      .split("/")
      .findIndex((id) => id === item.id);
    if (currentIndex > -1) {
      dispatch({ type: MENU_OPEN, id: item.id });
    }
    // eslint-disable-next-line
  }, [pathname]);

  return (
    <>
      <ListItemButton
        disabled={item.disabled}
        sx={{
          borderRadius: `${customization.borderRadius}px`,
          mb: 0.5,
          alignItems: "center",
          ml: 1,
        }}
        selected={customization.isOpen.findIndex((id) => id === item.id) > -1}
        onClick={() => itemHandler(item.id)}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <ListItemIcon
          sx={{
            my: "auto",
            minWidth: !item?.icon ? 18 : 36,
            display: "flex",
            justifyContent: "center",
          }}
        >
          <img src={item?.icon} alt="icon" width={18} height={18} />
        </ListItemIcon>
      </ListItemButton>
      <Popover
        id="mouse-over-popover"
        open={open}
        anchorEl={anchorEl}
        sx={{
          pointerEvents: "none",
        }}
        onClose={handleMouseLeave}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Typography
          sx={{ p: 2 }}
          variant="body1"
          color={
            customization.isOpen.findIndex((id) => id === item.id) > -1
              ? "primary"
              : "inherit"
          }
        >
          {item?.title}
        </Typography>
      </Popover>
    </>
  );
};

export default NavMiniDrawer;

