import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IRootState } from "../../../store/reducer";
import { getAllService } from "../../../store/Slice/Service";
import { Dispatch } from "redux";
import {
    Box,
    Grid,
    Typography,
    Divider,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Link } from "react-router-dom";
import {
    IconBrandFacebook,
    IconBrandTelegram,
    IconBrandX,
    IconBrandYoutube
} from "@tabler/icons-react";
import { gridSpacing } from "../../../store/constant";

interface props {
    open: boolean;
}

// const FooterStyled = styled()

const Footer = ({ open }: props) => {
    const theme = useTheme();

    const dispatch: Dispatch<any> = useDispatch();
    const service: any = useSelector(
        (state: IRootState) => state.service.service
    );

    useEffect(() => {
        dispatch(getAllService());
    }, []);

    const getIcon = (name: string) => {
        switch (name) {
            case "facebook":
                return <IconBrandFacebook width={30} height={30} strokeWidth={1.5} />;
            case "twitter":
                return <IconBrandX width={30} height={30} strokeWidth={1.5} />;
            case "instagram":
                return <IconBrandTelegram width={30} height={30} strokeWidth={1.5} />;
            case "youtube":
                return <IconBrandYoutube width={30} height={30} strokeWidth={1.5} />;
            default:
                return null;
        }
    };

    return (
        <>
            <Box sx={{ background: theme.palette.background.default }} padding={3}>
                <Grid container spacing={gridSpacing} sx={{ mb: 1 }}>
                    <Grid item xs={6} sm={6} md={2} lg={2}>
                        <Link to="/about" style={{ textDecoration: "none" }}>
                            <Typography
                                variant="h5"
                                color={theme.palette.text.primary}
                                gutterBottom
                                mb={2}
                                sx={{
                                    "&:hover": {
                                        color: theme.palette.primary.main,
                                    },
                                }}
                            >
                                About us
                            </Typography>
                        </Link>
                    </Grid>
                    <Grid item xs={6} sm={6} md={2} lg={2}>
                        <Link to="/privacy" style={{ textDecoration: "none" }}>
                            <Typography
                                variant="h5"
                                color={theme.palette.text.primary}
                                gutterBottom
                                mb={2}
                                sx={{
                                    "&:hover": {
                                        color: theme.palette.primary.main,
                                    },
                                }}
                            >
                                Term & Privacy
                            </Typography>
                        </Link>
                    </Grid>
                    <Grid item xs={6} sm={6} md={2} lg={2}>
                        <Link
                            to="https://docs.x-or.cloud/x-or-cloud/cam-ket-chat-luong-dich-vu-sla"
                            style={{ textDecoration: "none" }}
                            target="_blank"
                        >
                            <Typography
                                variant="h5"
                                color={theme.palette.text.primary}
                                gutterBottom
                                mb={2}
                                sx={{
                                    "&:hover": {
                                        color: theme.palette.primary.main,
                                    },
                                }}
                            >
                                SLA
                            </Typography>
                        </Link>
                    </Grid>
                    <Grid item xs={6} sm={6} md={2} lg={2}>
                        <Link
                            to="https://docs.x-or.cloud/san-pham"
                            style={{ textDecoration: "none" }}
                            target="_blank"
                        >
                            <Typography
                                variant="h5"
                                color={theme.palette.text.primary}
                                gutterBottom
                                mb={2}
                                sx={{
                                    "&:hover": {
                                        color: theme.palette.primary.main,
                                    },
                                }}
                            >
                                Documentation
                            </Typography>
                        </Link>
                    </Grid>
                    <Grid item xs={6} sm={6} md={2} lg={2}>
                        <Link
                            to="https://docs.x-or.cloud/tutorials"
                            style={{ textDecoration: "none" }}
                            target="_blank"
                        >
                            <Typography
                                variant="h5"
                                color={theme.palette.text.primary}
                                gutterBottom
                                mb={2}
                                sx={{
                                    "&:hover": {
                                        color: theme.palette.primary.main,
                                    },
                                }}
                            >
                                Tutorials
                            </Typography>
                        </Link>
                    </Grid>
                    <Grid item xs={6} sm={6} md={2} lg={2}>
                        <Link to="/contact" style={{ textDecoration: "none" }}>
                            <Typography
                                variant="h5"
                                color={theme.palette.text.primary}
                                gutterBottom
                                mb={2}
                                sx={{
                                    "&:hover": {
                                        color: theme.palette.primary.main,
                                    },
                                }}
                            >
                                Contact us
                            </Typography>
                        </Link>
                    </Grid>
                </Grid>
                <Divider />
                <Box
                    sx={{
                        height: "40px",
                        backgroundColor: theme.palette.background.default,
                        padding: 2,
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "start",
                        alignItems: "center",
                        mt: 2
                    }}
                >
                    {service?.social?.map((social: any, i: number) => {
                        return (
                            <Link
                                to={social?.link}
                                target="_blank"
                                style={{ color: theme.palette.primary.main.toString() }}
                                key={i}
                            >
                                <Box sx={{ mr: 0.5 }}>
                                    {getIcon(social?.name)}
                                </Box>
                            </Link>
                        );
                    })}
                </Box>
            </Box>
        </>
    );
};

export default Footer;
