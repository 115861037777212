// ElasticLoadBalance Component

import React, { useEffect } from 'react';
import { useLocation, useParams } from "react-router-dom";
import ProductDetailComponent from './ProductDetailComponent';
import { useSelector } from 'react-redux';
import { IRootState } from '../../store/reducer';


function ProductPage() {
    const location = useLocation();
    const cateId = location.state?.categoryId;
    const category = useSelector((state: IRootState) => state.category.categoryById);

    const categoryName = location?.state?.categoryName || category?.attributes?.name;
    useEffect(() => {
        // Access the state from the location object

      }, [cateId]);

    const productDetail = useSelector((state: IRootState) => state.product.product);
    
    return (
        <>
        { productDetail && 
            (  
               <ProductDetailComponent productDetail={productDetail} categoryId={cateId} categoryName={categoryName}/>
            )
        }
        </>
    );
}

export default ProductPage;
