import React, { useEffect, useState, createContext, ReactNode, useContext } from "react";
import Keycloak, { KeycloakConfig } from "keycloak-js";
import Cookies from "js-cookie";

type AuthContextType = {
  isAuthenticated: boolean;
  login: () => void;
  register: () => void;
  keycloakValue: any;
  logout: () => void;
};


export const AuthContext = createContext<AuthContextType | undefined>(undefined);

const logoutUrl = process.env.REACT_APP_KEYCLOAK_LOGOUT_URL!;
const keycloakUrl = process.env.REACT_APP_KEYCLOAK_URL!;
const keycloakRealm = process.env.REACT_APP_KEYCLOAK_REALM!;
const keycloakClientId = process.env.REACT_APP_KEYCLOAK_CLIENT!;
const keycloakSecret = process.env.REACT_APP_KEYCLOAK_CLIENT_SECRET!;


const initOptions: KeycloakConfig = {
  url: keycloakUrl,
  realm: keycloakRealm,
  clientId: keycloakClientId,
};


export const AuthProvider: React.FC<any> = ({ children }) => {

  let [isAuthenticated, setIsAuthenticated] = React.useState(false);
  const [keycloakValue, setKeycloakValue] = useState<any>(null);


  const keycloak = new Keycloak(initOptions);

  useEffect(() => {
    const initKeycloak = async () => {
      try {
        await keycloak.init({});
        setKeycloakValue(keycloak);
        setIsAuthenticated(!!keycloak.authenticated);

        const accessToken = Cookies.get('access_token');
        const refreshToken = Cookies.get('refresh_token');

        if (accessToken && refreshToken) {
          keycloak.token = accessToken;
          keycloak.refreshToken = refreshToken;

          // Check if the access token is still valid
          if (!keycloak.authenticated) {
            // Access token is expired, refresh it using the refresh token
            try {
              await keycloak.updateToken(30); // Pass the minValidity argument
              Cookies.set('access_token', keycloak.token || '');
              Cookies.set('refresh_token', keycloak.refreshToken || '');
              setIsAuthenticated(true);
            } catch (error) {
              console.error('Error refreshing token:', error);
              setIsAuthenticated(false);
            }
          } else {
            // Access token is still valid
            setIsAuthenticated(true);
          }
        }
      } catch (error) {
        console.error('Keycloak initialization failed', error);
      }
    };

    initKeycloak();

    keycloak.onAuthSuccess = () => {
      setIsAuthenticated(true);
      setKeycloakValue(keycloak);
      setIsAuthenticated(!!keycloak.authenticated);
      Cookies.set('access_token', keycloak.token || '');
      Cookies.set('refresh_token', keycloak.refreshToken || '');
    };

    keycloak.onAuthError = () => {
      setIsAuthenticated(false);
    };
  }, []);


  const login = async () => {
    try {
      await keycloak.init({
        onLoad: 'login-required', // Supported values: 'check-sso' , 'login-required'
      });
    } catch (error) {
      console.log('Error initializing Keycloak:', error);
    }
  };

  const register = async () => {
    try {
      await keycloakValue.register();
    } catch (error) {
      console.error('Error registering user:', error);
    }
  }

  const logout = async () => {
    keycloakValue.logout();
    Cookies.remove('access_token');
    Cookies.remove('refresh_token');
    setIsAuthenticated(false);
  };

  return (
    <AuthContext.Provider value={{
      isAuthenticated,
      login,
      register,
      keycloakValue,
      logout
    }}>
      {children}
    </AuthContext.Provider>
  );
};

