import { Link } from "react-router-dom";
import React from "react";

// material-ui

import { useSelector } from "../../../store";
import { IRootState } from "../../../store/reducer";
import logo from "../../../assets/images/xorcloud-logo.png";

// project imports

// ==============================|| MAIN LOGO ||============================== //

const LogoSection = () => {
  const service = useSelector((state: IRootState) => state.service.service);
  const logoUrl = service?.logo?.data?.attributes?.url;
  const url = process.env.REACT_APP_SOURCE_API_URL;
  return (
    <Link to={"/"}>
      {logoUrl ? (
        <img
          src={`${url}${logoUrl}`}
          alt="logo"
          width="100px"
          style={{ objectFit: "cover" }}
        />
      ) : (
        <img
          src={logo}
          alt="logo"
          width="100px"
          style={{ objectFit: "cover" }}
        />
      )}
    </Link>
  );
};

export default LogoSection;
