import React, { useEffect } from "react";
//markdown
import ReactMarkdown from "react-markdown";

import { useDispatch, useSelector } from "react-redux";
import { IRootState } from "../../store/reducer";
import { Dispatch } from "redux";
import { getPrivacyPage } from "../../store/Slice/Privacy";
import { Box, Container, Typography } from "@mui/material";
import { Helmet } from "react-helmet";

const Privacy = () => {
  const privacy: any = useSelector(
    (state: IRootState) => state.privacy.privacy
  );
  const dispatch: Dispatch<any> = useDispatch();
  useEffect(() => {
    dispatch(getPrivacyPage());
  }, [dispatch]);

  return (
    <>
      {privacy && (
        <Helmet>
          <meta name="description" content={privacy?.content}></meta>
        </Helmet>
      )}
      <Container style={{ marginTop: 50, minHeight: "100vh" }} maxWidth="md">
        <Box textAlign="center">
          <Typography variant="h1" sx={{ mb: 8 }} textAlign="center">
            {privacy.title}
          </Typography>
        </Box>
        <ReactMarkdown>{privacy.content}</ReactMarkdown>
      </Container>
    </>
  );
};

export default Privacy;
