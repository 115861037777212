import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL, domain } from "../../api/config";

interface ImageProps {
  image: string;
  link: string;
}

interface IHomeProps {
  [x: string]: any;
  title: string;
  images: ImageProps[];
  content: string;
}

export interface InitialHomePage {
  home: IHomeProps;
}
export const initialHomePage: InitialHomePage = {
  home: {
    title: "",
    images: [],
    content: "",
  },
};

export const HomeSlice = createSlice({
  name: "home",
  initialState: initialHomePage,
  reducers: {
    getHomePageSuccess(state, action) {
      state.home = action.payload;
    },
  },
});

export const getAllHomePage = () => {
  return async (dispatch: any) => {
    try {
      const response = await axios.get(
        `${BASE_URL}/api/home-pages?filters[domain_service][type][$eq]=${domain}&populate=images&populate=images.image&populate=images.content_list&populate=images.buttons&populate=section_start_now&populate=section_start_now.start_button&populate=section_portal_demo&populate=section_portal_demo.image&populate=section_portal_demo.feature&populate=section_img_animations.images.image&populate=section_system_metrics&populate=section_system_metrics.metrics&populate=section_system_metrics.features&populate=section_system_metrics.features.icon`
      );
      dispatch(
        HomeSlice.actions.getHomePageSuccess(
          response?.data?.data?.[0]?.attributes
        )
      );
    } catch (error) {
      console.error(error);
    }
  };
};

export default HomeSlice.reducer;
