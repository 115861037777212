import * as actionTypes from "./action";
import config from "../config";
export interface inititalCustomziation {
  opened: boolean;
  isOpen: [];
  fontFamily: any;
  borderRadius: any;
  defaultId: string;
}
export const initialState: inititalCustomziation = {
  isOpen: [], // for active default menu
  defaultId: "default",
  fontFamily: config.fontFamily,
  borderRadius: config.borderRadius,
  opened: true,
};

export const customizationReducer = (
  state: inititalCustomziation = initialState,
  action: any
) => {
  let id;
  switch (action.type) {
    case actionTypes.MENU_OPEN:
      id = action.id;
      return {
        ...state,
        isOpen: [id],
      };
    case actionTypes.SET_MENU:
      return {
        ...state,
        opened: action.opened,
      };
    case actionTypes.SET_FONT_FAMILY:
      return {
        ...state,
        fontFamily: action.fontFamily,
      };
    case actionTypes.SET_BORDER_RADIUS:
      return {
        ...state,
        borderRadius: action.borderRadius,
      };
    default:
      return state;
  }
};
