import { useDispatch, useSelector } from "react-redux";
import { Outlet } from "react-router-dom";

// material-ui
import { styled, useTheme } from "@mui/material/styles";
import {
  AppBar,
  Box,
  CssBaseline,
  Toolbar,
  useMediaQuery,
} from "@mui/material";

// project imports

import Header from "./Header";
import Sidebar from "./Sidebar";

// import navigation from "";
import { drawerWidth } from "../../store/constant";
import { SET_MENU } from "../../store/action";

// assets
// import { IconChevronRight } from "@tabler/icons";
import { IRootState } from "../../store/reducer";
import { AuthProvider } from "../../keycloak/Auth";
import Footer from "./Footer/Footer";
import AlertCookie from "../../ui-component/AlertCookie";
import { useEffect, useState } from "react";
import Cookies from "js-cookie";

// import navigation from 'menu-items';

// styles
interface props {
  theme: any;
  open: boolean;
}

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }: props) => ({
    ...theme.typography.mainContent,
    borderRadius: 10,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    padding: 0,
    tr: theme.palette.tr,
    background: theme.palette.background.paper,
    transition: theme.transitions.create(
      "margin",
      open
        ? {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }
        : {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }
    ),
    [theme.breakpoints.up("md")]: {
      marginLeft: open ? 0 : -(drawerWidth - 70),
      width: `calc(100% - ${drawerWidth}px)`,
    },
    [theme.breakpoints.down("md")]: {
      marginLeft: "50px",
      width: `calc(100% - ${drawerWidth}px)`,
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: "10px",
      width: `calc(100% - ${drawerWidth}px)`,
      marginRight: "10px",
      padding: 0,
    },
  })
);

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {
  const theme = useTheme();

  const matchDownMd = useMediaQuery(theme.breakpoints.down("md"));
  // Handle left drawer
  const leftDrawerOpened = useSelector(
    (state: IRootState) => state.customization.opened
  );
  const [isOpenCookie, setIsOpenCookie] = useState<boolean>(false);
  const dispatch = useDispatch();
  const handleLeftDrawerToggle = () => {
    dispatch({ type: SET_MENU, opened: !leftDrawerOpened });
  };
  useEffect(() => {
    const hasLoggedInOnce = Cookies.get("hasCookie");
    if (hasLoggedInOnce) {
      setIsOpenCookie(false);
    }
    if (!hasLoggedInOnce) {
      setIsOpenCookie(true);
      Cookies.set("hasCookie", "true", { expires: 365 }); // Cookie sẽ hết hạn sau 365 ngày
    }
  }, []);

  const handleCloseAlertCookie = () => {
    setIsOpenCookie(false);
  };
  return (
    <>
      <Box sx={{ display: "flex", borderRadius: 50 }}>
        <CssBaseline />
        {/* header */}
        <AppBar
          enableColorOnDark
          position="fixed"
          color="inherit"
          elevation={0}
          sx={{
            background: theme.palette.background.default,
            transition: leftDrawerOpened
              ? theme.transitions.create("width")
              : "none",
          }}
        >
          <Toolbar>
            <AuthProvider>
              <Header handleLeftDrawerToggle={handleLeftDrawerToggle} />
            </AuthProvider>
          </Toolbar>
        </AppBar>

        {/* drawer */}
        <Sidebar
          drawerOpen={!matchDownMd ? leftDrawerOpened : !leftDrawerOpened}
          drawerToggle={handleLeftDrawerToggle}
        />

        {/* main content */}
        <Main theme={theme} open={leftDrawerOpened}>
          <Outlet />
          {isOpenCookie && <AlertCookie handleClose={handleCloseAlertCookie} />}

          <Footer open={leftDrawerOpened} />
        </Main>
      </Box>
    </>
  );
};

export default MainLayout;
