import { Box, Container, Grid, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { gridSpacing } from "../store/constant";
import { OfferCard } from "../views/pages/Blog/FeatureSection";
import { BASE_URL } from "../api/config";
import MetricCard from "./card/MetricCard";

const SystemMetricsSection = (section: any) => {

    const [currentValue, setCurrentValue] = useState(0);
    const [countMetric, setCountMetric] = useState({ value: 0, type: '' });

    // Function to count until the target value is reached
    // const countUntilValue = () => {
    //     if (currentValue < countMetric.value) {
    //         setTimeout(() => {
    //             setCurrentValue(currentValue + 1);
    //         }, 100);
    //     }
    // };


    useEffect(() => {
        if (countMetric.type === 'dynamic' && currentValue < countMetric.value) {
            setTimeout(() => {
                setCurrentValue(currentValue + 1);
            }, 100);
        }
    }, []);


    return (
        <Container sx={{ padding: 2 }}>
            <Typography
                variant="h1"
                textAlign={{ xs: "justify", sm: "justify", md: "center", lg: "center" }}
                // textAlign="justify"
                sx={{
                    fontSize: { xs: "1.5rem", sm: "2rem" },
                    fontWeight: 200,
                    lineHeight: 1.5,
                    padding: 2,
                }}
            >
                {section?.section?.attributes?.title}
            </Typography>
            <Typography
                textAlign={{ xs: "justify", sm: "justify", md: "center", lg: "center" }}
                // textAlign="justify"
                variant="body2"
                sx={{ fontSize: "1rem", padding: 2 }}
            >
                {section?.section?.attributes?.description}
            </Typography>
            <Grid container spacing={gridSpacing} sx={{ mt: 2, marginLeft: 0 }}>
                {section?.section?.attributes?.metrics?.map((metric: any) => {
                    return (
                        <Grid item xs={6} sm={6} md={3} lg={3} key={metric.id}>
                            <MetricCard value={metric.value} caption={metric.caption} type={metric.type} unit={metric.unit} />
                            {/* <Stack direction="column">
                            {metric.type === 'dynamic' ? (
                                    <>
                                        <Typography
                                            variant="h3"
                                            textAlign="start"
                                            sx={{
                                                fontSize: "1.5rem",
                                                fontWeight: 200,
                                                lineHeight: 1.5,
                                                filter:
                                                    "drop-shadow(rgb(130, 78, 220) 0px 0px 5px) drop-shadow(rgb(130, 78, 220) 0px 0px 10px)",
                                                opacity: 1,
                                            }}
                                        >
                                            {setCountMetric(metric)} {metric.unit}
                                        </Typography>
                                        <Typography variant="subtitle1" textAlign="start">
                                            {metric.caption}
                                        </Typography>
                                    </>
                                ) : (
                                    <>
                                        <Typography
                                            variant="h3"
                                            textAlign="start"
                                            sx={{
                                                fontSize: "1.5rem",
                                                fontWeight: 200,
                                                lineHeight: 1.5,
                                                filter:
                                                    "drop-shadow(rgb(130, 78, 220) 0px 0px 5px) drop-shadow(rgb(130, 78, 220) 0px 0px 10px)",
                                                opacity: 1,
                                            }}
                                        >
                                            {metric?.value} {metric?.unit}
                                        </Typography>
                                        <Typography variant="subtitle1" textAlign="start">
                                            {metric?.caption}
                                        </Typography>
                                    </>
                                )}
                            </Stack>

                                <Stack direction="column">
                                    <Typography
                                        variant="h3"
                                        textAlign="start"
                                        sx={{
                                            fontSize: "1.5rem",
                                            fontWeight: 200,
                                            lineHeight: 1.5,
                                            filter:
                                                "drop-shadow(rgb(130, 78, 220) 0px 0px 5px) drop-shadow(rgb(130, 78, 220) 0px 0px 10px)",
                                            opacity: 1,
                                        }}
                                    >
                                        {metric?.value} {metric?.unit}
                                    </Typography>
                                    <Typography variant="subtitle1" textAlign="start">
                                        {metric?.caption}
                                    </Typography>
                                </Stack> */}
                        </Grid>
                    );
                })}
            </Grid>
            <Grid container spacing={5} sx={{ mt: 2 }}>
                {section?.section?.attributes?.features?.map((feature: any) => {
                    return (
                        <Grid item xs={12} sm={12} md={6} lg={6} key={feature.id}>
                            <OfferCard
                                title={feature.title}
                                caption={feature.description}
                                image={`${BASE_URL}${feature.icon.data.attributes.url}`}
                            />
                        </Grid>
                    );
                })}
            </Grid>
        </Container>
    );
};

export default SystemMetricsSection;


