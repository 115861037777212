import PropTypes from "prop-types";
import React, { useEffect, useState, SyntheticEvent } from "react";
import { Link, useLocation, useParams } from "react-router-dom";

// material-ui
import {
  Box,
  Grid,
  Tab,
  Tabs,
  Divider,
  useMediaQuery,
  Stack,
} from "@mui/material";
import { useTheme } from "@mui/system";

// assets
import { dispatch } from "../../store";
import {
  getCategories,
  getSingleCategories,
} from "../../store/slices/category";
import { useSelector } from "react-redux";
import { IRootState } from "../../store/reducer";
import PackagePrice from "./PackagePrice";
import MainCard from "../../ui-component/card/MainCard";
import { getSingleProduct } from "../../store/slices/product";
import MobileCard from "./pricecard/MobileCard";

function TabPanel({
  children,
  value,
  name,
  index,
  theme,
}: {
  children: React.ReactElement<any>;
  value: number;
  name: string;
  index: number;
  theme: any;
}) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      style={{ position: "relative" }}
    >
      {value === index && (
        <Box
          sx={{
            p: 2,
            // border: `1px solid ${theme.palette.primary.main}`,
            borderRadius: 2,
            minHeight: "100vh",
          }}
        >
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

// ==============================|| SOCIAL PROFILE ||============================== //

const CategoryTab = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery("(max-width:960px)");
  const categories = useSelector(
    (state: IRootState) => state.category.categories
  );

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const productIdFromUrl = searchParams.get("product");

  const categoryIdFromUrl = searchParams.get("category");

  const sortedCategories = [...categories]?.sort((a, b) => {
    const orderA = a.attributes?.order || 0;
    const orderB = b.attributes?.order || 0;
    return orderA - orderB;
  });

  const product = useSelector((state: IRootState) => state.product.product);

  const [optionId, setOptionId] = useState(0);

  const [value, setValue] = React.useState<number>(0);

  const [subValue, setSubValue] = React.useState<number>(0);

  const [subOptionId, setSubOptionId] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await dispatch(getCategories());

        // Dispatch getCategories and wait for it to complete
        const initialCategoryId =
          parseInt(categoryIdFromUrl || "") || sortedCategories[0]?.id;
        const categoryId = tabOptions.map((option: any) => option?.id);
        let index = categoryId.indexOf(initialCategoryId);
        setValue(index > 0 ? index : 0);
        setOptionId(index > 0 ? index : 0);

        // Now that categories are updated, get the first category id
        dispatch(getSingleCategories(initialCategoryId));

        // Dispatch getSingleCategories and wait for it to complete
        let initialProductId =
          parseInt(productIdFromUrl || "") ||
          sortedCategories[0]?.attributes?.products?.data[0]?.id;
        const productArray = tabOptions[value]?.subOptions.map(
          (id: any) => id?.id
        );
        const productIndex = productArray.indexOf(initialProductId);
        setSubValue(productIndex > 0 ? productIndex : 0);
        setSubOptionId(productIndex > 0 ? productIndex : 0);
        dispatch(
          getSingleProduct(subValue === 0 ? productArray[0] : initialProductId)
        );
      } catch (error) {
        // Handle errors appropriately
        console.error("Error fetching categories:", error);
      }
    };
    fetchData();
  }, [sortedCategories[0]?.id, value, optionId, subValue, subOptionId]);

  const tabValue = sortedCategories?.map((category: any) => {
    const productsTabOptions = category?.attributes?.products?.data?.map(
      (product: any) => {
        const parts = product?.attributes?.slug?.split("/");
        const firstValue = parts[1];
        return {
          id: product?.id,
          name: product?.attributes?.name,
          to: `/pricing?category=${category?.id}&product=${product.id}`,
          label: product?.attributes?.name,
          type: firstValue,
        };
      }
    );

    if (
      productsTabOptions?.length > 0 &&
      productsTabOptions[0]?.type !== "blog"
    ) {
      return {
        id: category?.id,
        name: category?.attributes?.name,
        products: category?.attributes?.products?.data,
        to: `/pricing?category=${category?.id}`,
        label: category?.attributes?.name,
        subOptions: productsTabOptions,
        // package: category?.attributes?.products?.data[0]?.package?.data?.length > 0 ? true : false,
      };
    } else {
      return null;
    }
  });

  const tabOptions = tabValue?.filter((item: any) => item !== null);

  const handleChange = (
    event: SyntheticEvent<Element, Event>,
    newValue: number
  ): void => {
    setValue(newValue);
    setOptionId(newValue);
    setSubValue(0);
    setSubOptionId(0);
  };

  const handleSubChange = (
    event: SyntheticEvent<Element, Event>,
    newValue: number
  ): void => {
    setSubValue(newValue);
    setSubOptionId(newValue);
  };

  const handleGetPackage = (id: number) => {
    dispatch(getSingleProduct(id));
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Box sx={{ width: "100%" }}>
          <Tabs
            value={value}
            variant="scrollable"
            onChange={handleChange}
            sx={{
              marginTop: 2,
              "& .MuiTabs-flexContainer": {
                border: "none",
              },
              "& a.Mui-selected": {
                borderTopRightRadius: 5,
                borderTopLeftRadius: 5,
                backgroundColor: theme.palette.background.default,
              },
            }}
          >
            {tabOptions.map((option: any) => {
              return (
                <Tab
                  key={option?.id}
                  component={Link}
                  to={option?.to}
                  iconPosition="start"
                  label={option?.label}
                  {...a11yProps(option?.id)}
                  sx={{ fontWeight: "bolder", fontSize: "1rem" }}
                  onClick={() => handleGetPackage(option?.products[0]?.id)}
                />
              )
            })}

            {/* {tabOptions?.map((option: any) => {
              return (
                <Tab
                  key={option?.id}
                  component={Link}
                  to={option?.to}
                  // icon={option?.icon}
                  iconPosition="start"
                  label={option?.label}
                  {...a11yProps(option?.id)}
                  sx={{ fontWeight: "bolder", fontSize: "1rem" }}
                  onClick={() => handleGetPackage(option?.products[0]?.id)}
                />
              );
            })} */}
          </Tabs>
        </Box>
      </Grid>
      <Grid
        item
        xs={12}
        sx={{
          borderRadius: 2,
          backgroundColor: theme.palette.background.default,
        }}
      >
        <TabPanel
          value={value}
          index={optionId}
          name={tabOptions[value]?.name}
          theme={theme}
        >
          {isSmallScreen ? (
            <>
              {tabOptions[value]?.subOptions && (
                <Tabs
                  value={subValue}
                  variant="scrollable"
                  onChange={handleSubChange}
                  sx={{
                    "& .MuiTabs-flexContainer": {
                      border: "none",
                      position: "absolute",
                    },
                    "& a.Mui-selected": {
                      color: "primary.main",
                      textDecoration: "none",
                    },
                  }}
                >
                  {tabOptions[value]?.subOptions.map((subOption: any) => {
                    return (
                      <Tab
                        key={subOption?.id}
                        component={Link}
                        to={subOption?.to}
                        label={subOption?.label}
                        {...a11yProps(subOption?.id)}
                        sx={{ fontSize: "0.8rem" }}
                        onClick={() => handleGetPackage(subOption?.id)}
                      />
                    )
                  })}
                </Tabs>
              )}

              {/* Content for the selected subOption */}

              <TabPanel
                value={subValue}
                index={subOptionId}
                name={tabOptions[value]?.subOptions[subValue]?.name}
                theme={theme}
              >
                <MainCard sx={{ background: theme.palette.background.paper }}>
                  <Stack
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      flexWrap: "wrap",
                    }}
                  >
                    <MobileCard pack={product} />
                  </Stack>
                </MainCard>
              </TabPanel>
            </>
          ) : (
            <>
              {tabOptions[value]?.subOptions && (
                <Tabs
                  value={subValue}
                  variant="scrollable"
                  onChange={handleSubChange}
                  sx={{
                    marginTop: 2,
                    "& .MuiTabs-flexContainer": {
                      border: "none",
                      position: "absolute",
                      // top: '100%',
                      // zIndex: 1,
                    },
                    "& a.Mui-selected": {
                      color: "primary.main",
                      textDecoration: "none",
                    },
                  }}
                >
                  {tabOptions[value]?.subOptions.map((subOption: any) => {
                    return (
                      <Tab
                        key={subOption.id}
                        label={subOption.label}
                        component={Link}
                        to={subOption?.to}
                        {...a11yProps(subOption?.id)}
                        sx={{ fontSize: "0.8rem" }}
                        onClick={() => handleGetPackage(subOption?.id)}
                      />
                    );
                  })}
                </Tabs>
              )}

              {/* Content for the selected subOption */}
              {tabOptions[value]?.subOptions && (
                <TabPanel
                  value={subValue}
                  index={subOptionId}
                  name={tabOptions[value]?.subOptions[subValue]?.name}
                  theme={theme}
                >
                  <PackagePrice product={product} />
                </TabPanel>
              )}
            </>
          )}
        </TabPanel>
      </Grid>
    </Grid>
  );
};

export default CategoryTab;
