
import PropTypes from "prop-types";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { dispatch } from "../../../../../store";

// material-ui
import { useTheme } from "@mui/material/styles";
import {
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  useMediaQuery,
} from "@mui/material";

// project imports
import { MENU_OPEN, SET_MENU } from "../../../../../store/action";

// assets
import { IRootState } from "../../../../../store/reducer";
import { getSingleProduct } from "../../../../../store/slices/product";

// ==============================|| SIDEBAR MENU LIST ITEMS ||============================== //
interface props {
  item: any;
  category: any;
}

const NavItem: React.FC<props> = ({ item, category }) => {

  const theme: any = useTheme();
  const { pathname } = useLocation();
  const customization = useSelector((state: IRootState) => state.customization);
  const matchesSM = useMediaQuery(theme.breakpoints.down("lg"));

  const navigate = useNavigate();

  const itemHandler = async (id: number) => {
    dispatch({ type: MENU_OPEN, id });
    if (matchesSM) dispatch({ type: SET_MENU, opened: false });
    dispatch(getSingleProduct(id));

    navigate(item?.url.toLowerCase(), { state: { categoryId: category?.id, categoryName: category?.title } } as any);
  };

  // active menu item on page load
  useEffect(() => {
    const currentIndex = document.location.pathname
      .toString()
      .split("/")
      .findIndex((id) => id === item.id);
    if (currentIndex > -1) {
      dispatch({ type: MENU_OPEN, id: item.id });
    }
    // eslint-disable-next-line
  }, [pathname]);



  return (
    <ListItemButton
      disabled={item.disabled}
      sx={{
        borderRadius: `${customization.borderRadius}px`,
        mb: 0.5,
        alignItems: "center",
        ml: 1
      }}
      selected={customization.isOpen.findIndex((id) => id === item.id) > -1}
      onClick={() => itemHandler(item.id)}
    >
      <ListItemIcon sx={{ my: "auto", minWidth: !item?.icon ? 18 : 36 }}>
        <img src={item?.icon} alt="icon" width={18} height={18} />
      </ListItemIcon>
      <ListItemText
        primary={
          <Typography
            variant="h6"
            color={customization.isOpen.findIndex((id) => id === item.id) > -1 ? "primary" : "inherit"}
            sx={{ marginLeft: "10px" }}
          >
            {item.title}
          </Typography>
        }
      />
    </ListItemButton>
  );
};

NavItem.propTypes = {
  item: PropTypes.object,
};

export default NavItem;




