
import React, { useState, useEffect } from "react";
import axios from "axios";
// assets
import { BASE_URL, domain } from "../api/config";

const MenuData = () => {
  const [menuData, setMenuData] = useState<any[]>([]);

  useEffect(() => {
    const apiUrl = `${BASE_URL}/api/categories?filters[domain_services][type][$eq]=${domain}&populate=products&populate=products.icon`;
    axios
      .get(apiUrl)
      .then((response: any) => {
        const categories = response.data.data;
        const sortedCategories = categories?.sort((a: any, b: any) => {
          const orderA = a.attributes?.order || 0;
          const orderB = b.attributes?.order || 0;
          return orderA - orderB;
        });
        const finalMenuData = sortedCategories?.map((category: any, index: number) => {
          const iconUrl = category?.attributes?.icon?.data?.map(
            (icon: any) => {
              return `${BASE_URL}${icon?.attributes.url}`
            });

          const productList = category?.attributes?.products?.data.map((child: any) => {
            const iconUrl = child?.attributes?.icon?.data?.attributes?.url
            return {
              id: child.id.toString(),
              title: child.attributes.name,
              type: "item",
              target: true,
              icon: `${BASE_URL}${iconUrl}`,
              url: `${child?.attributes?.slug}?category_id=${category.id}&product_id=${child?.id}`,
            };
          })

          return {
            id: category.id.toString(),
            title: category.attributes.name,
            type: "group",
            icon: { iconUrl },
            children: productList
          };
        });
        setMenuData(finalMenuData);
      })
      .catch((error: any) => {
        console.error("Error fetching categories:", error);
      });
  }, []);

  return menuData;
};

export default MenuData;
